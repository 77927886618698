import styled from 'styled-components';

import { mq } from 'theme/media';
import { articleBody } from 'theme/markdownMixins';

export const StyledContent = styled.div`
  padding: ${({ theme }) => theme.spacing(4, 0)};
  background: ${({ theme }) => theme.gradients.whisper};
  max-width: 940px;
  width: 100%;
  margin: 0 auto;

  ${mq.large`
      padding: ${({ theme }) => theme.spacing(8, 11)};
  `}
`;

export const StyledMarkedContent = styled.div`
  ${articleBody};
  margin: ${({ theme }) => theme.spacing(2, 0)};

  & span > :first-child {
    padding-top: 0;
    margin-top: 0;
  }

  & h2 {
    ${({ theme }) => theme.fonts.h4};
  }

  & em {
    font-style: italic;
  }
`;

export const StyledContainer = styled.article`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
