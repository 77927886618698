import styled, { css } from 'styled-components';

export const StyledImage = styled.img<{ cover?: boolean }>`
  ${(props) =>
    props.cover &&
    css`
      width: 100%;
      height: 100%;
      object-fit: cover;
    `}
`;
