import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const InLogo: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M63 12H17a5 5 0 00-5 5v46a5 5 0 005 5h46a5 5 0 005-5V17a5 5 0 00-5-5zM34 26.2c0 3-2.8 3.4-3.3 3.4a3.4 3.4 0 01-3.4-3.3c0-4.5 6.6-4.5 6.7-.1zm19.6 29c-8.2 0-4.3-9.3-3.1-13.4 1.7-5.8-5.7-7.4-7.5 0l-3.2 13H34l1-4c-3.9 6.4-11.8 5.5-11.8-.6 0-1.8.1-1.8 2.9-13h-4l1.2-4.5H33c-4.2 16.7-4.1 15.9-4.1 17.3 0 2.6 5 1.7 7.1-2.9l2.5-10h-4l1.2-4.4h8.6l-1.1 5.1c5-9.4 16-6 12.7 4.8-1.8 6.3-1.8 6.3-1.9 7.3 0 2 2.2 2.8 4-2.1l2.2.8c-1.3 4.6-3.6 6.5-6.6 6.5z"
      fill={color}
    />
  </svg>
);

export default withTheme(InLogo);
