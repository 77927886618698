import React, { FC, ElementType, ReactNode } from 'react';

import { StyledContainer } from './Container.styles';

type ContainerProps = {
  id?: string;
  children: ReactNode;
  renderAs?: ElementType;
};

const Container: FC<ContainerProps> = ({ renderAs, children, id }) => (
  <StyledContainer id={id} as={renderAs}>
    {children}
  </StyledContainer>
);

export default Container;
