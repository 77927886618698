import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Devops: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4492 15.8086h51.1016c2.1601 0 3.9101 1.7773 3.9101 3.9726v40.2696c0 2.2851-1.75 4.1406-3.9101 4.1406H14.4492c-2.1601 0-3.9101-1.8555-3.9101-4.1406V19.7812c0-2.1953 1.75-3.9726 3.9101-3.9726zm4.293 5.2656c0 .8867-.7188 1.6055-1.6055 1.6055-.8867 0-1.6055-.7188-1.6055-1.6055 0-.8867.7188-1.6055 1.6055-1.6055.8867 0 1.6055.7188 1.6055 1.6055zm6.4805 0c0 .8867-.7188 1.6055-1.6055 1.6055-.8867 0-1.6055-.7188-1.6055-1.6055 0-.8867.7188-1.6055 1.6055-1.6055.8867 0 1.6055.7188 1.6055 1.6055zm4.8789 1.6055c.8867 0 1.6054-.7188 1.6054-1.6055 0-.8867-.7187-1.6055-1.6054-1.6055-.8868 0-1.6055.7188-1.6055 1.6055 0 .8867.7187 1.6055 1.6055 1.6055zm-15.25 3.668h50.2968v32.5742H14.8516zM42.082 31.453c.297-.8633 1.2383-1.3242 2.1016-1.0273.8672.293 1.3281 1.2383 1.0312 2.1015L37.918 53.8398c-.2344.6875-.879 1.1211-1.5664 1.1211-.1797 0-.3594-.0312-.5391-.0898-.8633-.2969-1.3242-1.2383-1.0273-2.1055zm-9.5312 2.8672c-.6446-.6484-1.6953-.6484-2.3438 0l-7.1562 7.1563a1.6585 1.6585 0 00-.4844 1.1718c0 .4375.1758.8594.4844 1.168l7.1562 7.1563c.3242.3242.75.4882 1.172.4882.4257 0 .8476-.164 1.1718-.4882.6484-.6446.6484-1.6915 0-2.3399l-5.9883-5.9844 5.9883-5.9882c.6484-.6485.6484-1.6954 0-2.3399zm24.3984 7.1563l-7.1562-7.1563a1.655 1.655 0 00-2.3438 0c-.6445.6445-.6445 1.6953 0 2.3399l5.9883 5.9882-5.9883 5.9844c-.6445.6484-.6445 1.6953 0 2.3399.3242.3242.75.4882 1.1719.4882.4258 0 .8477-.164 1.1719-.4882l7.1562-7.1563c.3125-.3086.4844-.7305.4844-1.168 0-.4414-.1719-.8632-.4844-1.1718zm0 0"
      fillRule="evenodd"
      fill={color}
      fillOpacity=".9"
    />
  </svg>
);

export default withTheme(Devops);
