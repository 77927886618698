import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledContainer = styled.div`
  position: fixed;
  background: ${({ theme }) =>
    `linear-gradient(214.84deg, ${theme.colors.violet} 20.52%, ${theme.colors.blackRock} 89.43%)`};
  z-index: 999;
  display: flex;
  width: 100%;
  flex-direction: column;
  bottom: 0;
  left: 0;
  padding: ${({ theme }) => theme.spacing(4, 3)};

  ${mq.medium`
    left: 50%;
    margin-left: ${({ theme }) => theme.spacing(-47)};
    width: 750px;
    height: 140px;
    padding: ${({ theme }) => theme.spacing(0, 5, 0, 8)};
    align-items: center;
    bottom: ${({ theme }) => theme.spacing(3)};
    flex-direction: row;
    border-radius: ${({ theme }) => theme.borderRadius.sm};
  `}
`;

export const StyledDescription = styled.span`
  color: ${({ theme }) => theme.colors.white};
  margin-left: ${({ theme }) => theme.spacing(3)};
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(4)};

  ${mq.medium`
    margin-top: 0;
  `}
`;

export const StyledDescriptionContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
