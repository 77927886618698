import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Ios: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M49.9 17.3c-.6 1.6-1.4 3.2-2.5 4.4a12 12 0 01-4 3.2 9 9 0 01-4.6 1l-.5-.1v-.5a10 10 0 01.5-4.5 13.1 13.1 0 0111-8.7l.5-.1.1.6c.2 1.6 0 3.2-.5 4.7z"
      fill={color}
    />
    <path
      d="M60 31.8c-.5.4-5.6 3.5-5.6 10 .1 7.6 6.7 10.3 7 10.5l.5.2-.1.5a31 31 0 01-7.6 12.6A7.6 7.6 0 0149 68c-1.9 0-3.1-.5-4.4-1-1.3-.6-2.6-1.1-4.6-1.1-2.1 0-3.5.5-4.8 1-1.3.6-2.4 1.1-4.2 1.2-2 0-3.7-.9-5.2-2.3a35.3 35.3 0 01-9.6-19.9c-.6-4.6 0-9.3 2.2-13.2 1.2-2.2 3-4 5-5.2 2-1.3 4.4-2 6.8-2 2.1 0 4.1.8 5.9 1.5 1.2.4 2.3.9 3.2.9.7 0 1.8-.4 3-1 2.2-.8 4.7-1.8 7.5-1.5 1.1 0 3.5.3 6 1.6 1.6.9 3.2 2.2 4.5 4l.3.6-.5.3z"
      fill={color}
    />
  </svg>
);

export default withTheme(Ios);
