import styled from 'styled-components';

export const StyledCursorContainer = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
`;

export const StyledRhombusWrapper = styled.div<{ visible: boolean }>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: translate(-10px, -10px);
  transition: opacity 200ms;
`;
