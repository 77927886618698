import React, { FC, useState, useEffect } from 'react';
import { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import debounce from 'debounce';
import 'swiper/swiper-bundle.min.css';

import { Header } from 'components/molecules/Header/Header';
import { Footer } from 'components/Footer/Footer';
import { Cursor } from 'components/Cursor/Cursor';
// import { footerData } from 'components/Footer/footerData';
import { Cookies } from 'components/Cookies/Cookies';
import { ContactWidget } from 'components/ContactWidget/ContactWidget';
import { Portal } from 'components/Portal/Portal';
import MetaTags from 'components/MetaTags/MetaTags';
import PageTitle from 'components/PageTitle/PageTitle';
import { PreviewBanner } from 'components/PreviewBanner/PreviewBanner';

import { GlobalStyle } from 'theme/global-styles';
import theme from 'theme/theme';
import 'theme/fonts.css';
import { GTMPageView, useGTM } from 'utils/gtm';
import config from 'config';
import CookiesHandler from 'utils/Cookies';
import HeaderThemeProvider from 'utils/HeaderThemeContext';
import FooterSpaceProvider from 'utils/FooterSpaceContext';
import isBrowser from 'utils/isBrowser';
import fixVH from 'utils/fixVH';

const { COOKIES_ACCEPTED_KEY } = config;

Router.events.on('routeChangeComplete', GTMPageView);
SwiperCore.use([Navigation, Pagination]);

export const App: FC<AppProps> = ({ Component, pageProps }) => {
  const { page, menuStructure, footerData, isPreview } = pageProps;
  const [, setIsAccepted] = useGTM(false);
  const [isCookiesBannerVisible, setIsCookiesBannerVisible] = useState(false);
  const { pathname } = useRouter();
  const pathsWithoutContactWidget = [config.ROUTES.CAREERS.href, config.ROUTES.CONTACT.href];
  const shouldShowContactWidget = !pathsWithoutContactWidget.some((path) => pathname.includes(path));

  useEffect(() => {
    const isCookiePolicyAccepted = CookiesHandler.getCookie(COOKIES_ACCEPTED_KEY);

    setIsAccepted(isCookiePolicyAccepted);
    setIsCookiesBannerVisible(!isCookiePolicyAccepted);
  }, [setIsAccepted]);

  const handleAnswerClick = (answer: boolean) => {
    setIsCookiesBannerVisible(false);
    setIsAccepted(answer);
  };

  useEffect(() => {
    if (isBrowser) {
      fixVH();
      const updateVhDeb = debounce(fixVH, 100);
      window.addEventListener('resize', updateVhDeb);

      return () => {
        window.removeEventListener('resize', updateVhDeb);
      };
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <HeaderThemeProvider defaultTheme={{ mobile: 'light', desktop: 'light' }}>
        <FooterSpaceProvider>
          {page?.title && <PageTitle title={page.title} />}
          {page?.metaTags && (
            <MetaTags
              metaTags={page.metaTags.meta}
              ogImage={page.metaTags.ogImage}
              data={page.metaTags.data}
              link={page.metaTags.link}
            />
          )}
          {shouldShowContactWidget && (
            <Portal>
              <ContactWidget />
            </Portal>
          )}
          {isPreview && <PreviewBanner />}
          <Header menuStructure={menuStructure} />
          <Component {...pageProps} />
          <Footer {...footerData} />
          <Cursor />
        </FooterSpaceProvider>
      </HeaderThemeProvider>
      {isCookiesBannerVisible && <Cookies onAnswerClick={handleAnswerClick} />}
    </ThemeProvider>
  );
};

export default App;
