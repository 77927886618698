import React, { FC } from 'react';

import Typography from 'components/atoms/Typography/Typography';

import { StyledCommentContainer, StyledContainer, StyledImage } from './Comment.styles';

export type CommentProps = {
  image: string;
  name: string;
  comment: string;
};

export const Comment: FC<CommentProps> = ({ image, name, comment }) => (
  <StyledContainer>
    <StyledImage src={image} width={42} height={42} objectFit="cover" hasFade={false} />
    <StyledCommentContainer>
      <Typography variant="body2" weight="600">
        {name}
      </Typography>
      <Typography variant="subtitle">{comment}</Typography>
    </StyledCommentContainer>
  </StyledContainer>
);
