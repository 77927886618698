import styled, { css } from 'styled-components';

import { mq } from 'theme/media';

import { ContentBoxProps } from './ContentBox';

const crops = {
  leftToRight: 'polygon(0 0, calc(100% - 100px) 0, 100% 100%, 0% 100%)',
  rightToLeft: 'polygon(0 0, 100% 0, calc(100% - 100px) 100%, 0% 100%)'
};

const columnColors = {
  dark: ({ theme }) => theme.colors.blackRock,
  white: ({ theme }) => theme.colors.white,
  violet: ({ theme }) => theme.gradients.purple,
  purple: ({ theme }) => theme.gradients.electricVioletReverse,
  whisper: ({ theme }) => theme.colors.whisper
};

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  margin: auto;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
`;

export const StyledLeftColumn = styled.div<{
  crop?: ContentBoxProps['crop'];
  leftColumnColor?: ContentBoxProps['leftColumnColor'];
}>`
  position: relative;
  flex: 2;

  ${({ crop }) =>
    crop &&
    css`
      flex: 4;
    `}

  ${mq.large`
    &::before {
      content: '';
      position: absolute;
      width: 100vw;
      height: 100%;
      right: 0;
      top: 0;
      background: ${({ leftColumnColor }) => columnColors[leftColumnColor]};
      clip-path: ${({ crop }) => crop && crops[crop]};
      z-index: 1;

      ${({ crop }) =>
        crop &&
        css`
          right: ${({ theme }) => theme.spacing(-13)};
        `}
    }
  `}
`;

export const StyledBackground = styled.div<{
  rightColumnColor?: ContentBoxProps['rightColumnColor'];
}>`
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  background: ${({ rightColumnColor }) => columnColors[rightColumnColor]};
  height: 100%;
  opacity: 0.9;

  ${mq.large`
    width: 100vw;
    opacity: 1;
  `}
`;

export const StyledRightColumn = styled.div<{ crop?: ContentBoxProps['crop'] }>`
  flex: 8;
  position: relative;
  display: none;

  ${({ crop }) =>
    crop &&
    css`
      flex: 6;
    `}

  ${mq.large`
    display: block;
  `}
`;

export const StyledWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const StyledMobileBackgroundWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;

  ${mq.large`
    display: none;
  `}
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
