import styled from 'styled-components';

import { arrowAnimationRight } from 'theme/animations/animations';

export const StyledArrowLink = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.heliotrope};
  cursor: pointer;

  &:hover {
    svg {
      animation: ${arrowAnimationRight} 2s infinite;
    }
  }
`;

export const StyledArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  /* stylelint-disable-next-line no-descending-specificity */
  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${({ theme }) => theme.colors.heliotrope};
    }
  }
`;
