import React, { FC } from 'react';

import { Rhombus } from 'components/atoms/Geometric/Rhombus/Rhombus';
import { StyledShapes } from 'components/LetsTalkAbout/GeoShapes/GeoShapes.styles';

const GeoShapes: FC = () => (
  <StyledShapes>
    <Rhombus
      width="13px"
      top="335px"
      left="80px"
      variant="whisper"
      rotate="45deg"
      moveDistance="60px"
      moveDuration="6s"
      moveDelay="0s"
    />
    <Rhombus
      width="16px"
      top="102px"
      left="169px"
      variant="white"
      rotate="45deg"
      moveDistance="90px"
      moveDuration="6s"
      moveDelay="1s"
    />
    <Rhombus
      width="16px"
      top="131px"
      left="273px"
      variant="purple"
      rotate="45deg"
      moveDistance="130px"
      moveDuration="6s"
      moveDelay="2s"
    />
    <Rhombus
      width="13px"
      top="161px"
      left="614px"
      variant="white"
      rotate="45deg"
      moveDistance="90px"
      moveDuration="6s"
      moveDelay="1s"
    />
    <Rhombus
      width="29px"
      top="82px"
      left="792px"
      variant="purple"
      rotate="45deg"
      moveDistance="30px"
      moveDuration="6s"
      moveDelay="2s"
    />
    <Rhombus
      width="13px"
      top="246px"
      left="930px"
      variant="whisper"
      rotate="45deg"
      moveDistance="-30px"
      moveDuration="6s"
      moveDelay="1s"
    />
    <Rhombus
      width="16px"
      top="162px"
      left="1078px"
      variant="purple"
      rotate="45deg"
      moveDistance="-60px"
      moveDuration="6s"
      moveDelay="2s"
    />
    <Rhombus
      width="29px"
      top="218px"
      left="1250px"
      variant="white"
      rotate="45deg"
      moveDistance="-20px"
      moveDuration="6s"
      moveDelay="1s"
    />
  </StyledShapes>
);

export default GeoShapes;
