import styled, { css } from 'styled-components';

import { arrowAnimationDown, arrowAnimationLeft, arrowAnimationRight } from 'theme/animations/animations';

import { ArrowProps } from './Arrow';

export const StyledArrow = styled.svg<{ direction: ArrowProps['direction']; hasAnimation: ArrowProps['hasAnimation'] }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ direction }) =>
    direction === 'down' &&
    css`
      transform: rotate(90deg);
    `}

  ${({ direction }) =>
    direction === 'top' &&
    css`
      transform: rotate(-90deg);
    `}

  ${({ direction }) =>
    direction === 'left' &&
    css`
      transform: rotate(180deg);
    `}

  ${({ direction, hasAnimation }) =>
    hasAnimation &&
    css`
      ${direction === 'down' &&
      css`
        animation: ${arrowAnimationDown} 2s infinite;
      `}

      ${direction === 'right' &&
      css`
        animation: ${arrowAnimationRight} 2s infinite;
      `}

      ${direction === 'left' &&
      css`
        animation: ${arrowAnimationLeft} 2s infinite;
      `}
    `}
`;
