import React, { FC, ReactElement } from 'react';
import Image from '@next/image';

import { revealBottomFast } from 'theme/animations/animations';

import ContentBox from 'components/ContentBox/ContentBox';
import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import { AppLink } from 'components/AppLink/AppLink';
import ArrowLink from 'components/molecules/Header/ArrowLink/ArrowLink';
import Typography from 'components/atoms/Typography/Typography';
import fromCMS from 'utils/fromCMS';
import { MenuItem, isDesktopVisible } from 'utils/types/Menu';
import { ColorScheme } from 'theme';

import { GeoShapes } from './GeoShapes';
import {
  StyledRightColumnWrapper,
  StyledColumnWrapper,
  StyledWrapper,
  StyledImageWrapper,
  StyledDropdownMenu,
  StyledTitleWrapper
} from './DropdownMenu.styles';

export type DropdownMenuProps = {
  colorScheme?: ColorScheme;
  isVisible: boolean;
  menuItem: MenuItem;
  subMenuItems: Array<MenuItem>;
  onMouseLeave: () => void;
};

type SubMenuProps = {
  colorScheme?: ColorScheme;
  menuItem: MenuItem;
  subMenuItems?: Array<MenuItem>;
};

const SubMenu: FC<SubMenuProps> = ({ colorScheme = 'light', menuItem, subMenuItems = [] }): ReactElement => {
  const { title, buttonName, buttonHref, image } = menuItem;
  const visibleMenuItems = subMenuItems.filter(isDesktopVisible);

  return (
    <ContentBox
      leftColumnColor={colorScheme === 'light' ? 'whisper' : 'dark'}
      leftColumnContent={
        <StyledWrapper>
          {image && (
            <StyledImageWrapper>
              <GeoShapes colorScheme={colorScheme} />
              <Image
                src={fromCMS(image.url)}
                alt={image.alternativeText}
                width={image.width}
                height={image.height}
                layout="responsive"
                sizes="40vw"
                hasFade={false}
              />
            </StyledImageWrapper>
          )}
          {title && (
            <StyledColumnWrapper>
              <StyledTitleWrapper colorScheme={colorScheme}>
                <Typography variant="h4" weight="700">
                  {title}
                </Typography>
              </StyledTitleWrapper>
              {buttonName && <LinkedButton href={buttonHref}>{buttonName}</LinkedButton>}
            </StyledColumnWrapper>
          )}
        </StyledWrapper>
      }
      rightColumnColor={colorScheme === 'light' ? 'whisper' : 'dark'}
      rightColumnContent={
        <StyledRightColumnWrapper>
          {visibleMenuItems.map(({ id, href, text }) => (
            <AppLink key={id} href={href}>
              <ArrowLink>{text}</ArrowLink>
            </AppLink>
          ))}
        </StyledRightColumnWrapper>
      }
    />
  );
};

const DropdownMenu: FC<DropdownMenuProps> = ({
  isVisible,
  onMouseLeave,
  colorScheme = 'light',
  menuItem,
  subMenuItems
}) => (
  <div style={{ visibility: isVisible ? 'visible' : 'hidden' }} onMouseLeave={onMouseLeave}>
    <StyledDropdownMenu
      animate={isVisible ? 'visible' : 'hidden'}
      initial="hidden"
      variants={revealBottomFast}
      exit="hidden"
    >
      <SubMenu colorScheme={colorScheme} menuItem={menuItem} subMenuItems={subMenuItems} />
    </StyledDropdownMenu>
  </div>
);

export default DropdownMenu;
