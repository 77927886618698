import { breakpoints } from 'theme/media';
import { getNextImgUrl } from 'helpers/image';

const MOBILE_IMAGE_SIZES = [breakpoints.small];

export const LARGEST_IMAGE_SIZE = Math.max(...MOBILE_IMAGE_SIZES);

export const SIZES = MOBILE_IMAGE_SIZES.map((size) => `(max-width: ${size}px) ${size}w`).join(', ');

export const getSrcSet = (url: string, quality: number): string =>
  MOBILE_IMAGE_SIZES.map((size) => `${getNextImgUrl(url, { quality, width: size })} ${size}w`).join(', ');
