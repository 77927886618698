const ROUTES = {
  HOME: {
    href: '/',
    name: 'Home'
  },
  ABOUT: {
    href: '/about',
    name: 'About'
  },
  EXTENSION: {
    href: '/team-extension',
    name: 'Team Extension'
  },
  SINGLE_TECHNOLOGY_EXTENSION: {
    href: '/team-extension/[slug]',
    name: 'Single technology extension'
  },
  SOFTWARE: {
    href: '/custom-software',
    name: 'Custom Software'
  },
  CAREERS: {
    href: '/careers',
    name: 'Careers'
  },
  SINGLE_JOB_OFFER: {
    href: '/careers/slug',
    name: 'Job offer'
  },
  NEWS: {
    href: '/frontend-news',
    name: 'Frontend News'
  },
  SERVICES: {
    href: '/services',
    name: 'Services'
  },
  SINGLE_FRONTEND_NEWS: {
    href: '/frontend-news/slug',
    name: 'Single Frontend News'
  },
  TECH_BLOCK: {
    href: '/blog',
    name: 'Tech Block'
  },
  BLOG: {
    href: '/blog',
    name: 'Blog'
  },
  BLOG_ARTICLE: {
    href: '/blog/[slug]',
    name: 'Blog article'
  },
  CASE_STUDY: {
    href: '/case-study',
    name: 'Case studies'
  },
  SINGLE_CASE_STUDY: {
    href: '/case-study/slug',
    name: 'Case study'
  },
  EXPERT_ZONE: {
    href: '/experts-zone',
    name: 'Experts zone'
  },
  EXPERT_ZONE_POST: {
    href: '/experts-zone/[slug]',
    name: 'Experts zone post'
  },
  TERMS_AND_CONDITIONS: {
    href: '/terms-and-conditions',
    name: 'Terms & Conditions'
  },
  CONTACT: {
    href: '/contact',
    name: 'Free consultation'
  },
  PRODUCT_DISCOVERY: {
    href: '/product-discovery',
    name: 'Product Discovery'
  }
};

const WE_CAN_HELP_ID = 'we-can-help-with';

export default { ROUTES, WE_CAN_HELP_ID };
