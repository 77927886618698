import spacing from './spacing';
import fonts from './fonts';

type Gradient = (firstColor: string, secondColor: string) => string;

const gradientsArray: Array<Gradient> = [
  (first, second) => `linear-gradient(90deg, ${first} 0%, ${second} 100%)`,
  (first, second) => `linear-gradient(270deg, ${first} 0%, ${second} 100%)`,
  (first, second) => `linear-gradient(215deg, ${first} 20%, ${second} 90%)`,
  (first, second) => `linear-gradient(240deg, ${first} 0%, ${second} 96%)`
];

const filters = {
  icon: 'grayscale(90%) opacity(50%)'
};

const colors = {
  black: '#1d1d1d',
  white: '#fff',
  violet: '#0f0944',
  blackRock: '#090432',
  daisyBush: '#431aa8',
  electricViolet: '#6e2aff',
  malibu: '#7090ff',
  cornflowerBlue: '#7759ff',
  heliotrope: '#7f5fff',
  heliotrope2: '#8462ff',
  heliotropeDark: '#9462ff',
  heliotropeContrast: '#6F4FFF',
  whisper: '#f9f9fc',
  violetGray: '#cbc8e6',
  cloudBurst: '#242364',
  whiteLilac: '#f4f3fa',
  persimmon: '#ff5a5a',
  candlelight: '#ffd110',
  transparent: 'rgba(0, 0, 0, 0)',
  transparentHalfBlack: 'rgba(0, 0, 0, 0.5)',
  transparentLightBlack: 'rgba(0, 0, 0, 0.09)',
  transparentShuttleGray: 'rgba(82, 92, 105, 0.11)',
  youtubePlayerOverlayBackground: '#000'
};

const gradients = {
  electricViolet: gradientsArray[0](colors.electricViolet, colors.heliotropeDark),
  electricVioletReverse: gradientsArray[1](colors.electricViolet, colors.heliotropeDark),
  purple: gradientsArray[1](colors.electricViolet, colors.heliotrope2),
  purpleReverse: gradientsArray[1](colors.heliotrope2, colors.electricViolet),
  whisper: gradientsArray[1](colors.whisper, colors.whiteLilac),
  darkViolet: gradientsArray[2](colors.violet, colors.blackRock),
  diagonalPurple: gradientsArray[3](colors.electricViolet, colors.heliotrope2)
};

const borderRadius = {
  sm: '4px',
  lg: '8px'
};

const sizes = {
  layoutWidth: '1140px',
  layoutPadding: '24px',
  container: {
    small: '540px',
    medium: '768px',
    large: '960px',
    xsLarge: '1140px'
  }
};

const contactFormVariant = {
  onWhite: {
    input: {
      background: 'rgba(117, 59, 255, 0.05)'
    },
    label: {
      color: '#242364'
    },
    checkbox: {
      color: 'rgba(9, 4, 50)',
      border: '2px solid rgba(9, 4, 50, 0.5)'
    },
    agreement: {
      color: '#242364'
    },
    check: {
      stroke: 'white'
    }
  },
  onViolet: {
    label: {
      color: 'white'
    },
    checkbox: {
      color: 'white',
      border: '2px solid #ffffff'
    },
    agreement: {
      color: 'white'
    },
    check: {
      stroke: '#6E2AFF'
    }
  }
};

export type ColorTypes = typeof colors;
export type FontTypes = typeof fonts;
export type FiltersTypes = typeof filters;
export type GradientTypes = typeof gradients;
export type SpacingTypes = typeof spacing;
export type BorderRadiusTypes = typeof borderRadius;
export type SizesTypes = typeof sizes;
export type ContactFormVariantTypes = typeof contactFormVariant;

export default {
  colors,
  fonts,
  filters,
  gradients,
  spacing,
  borderRadius,
  sizes,
  contactFormVariant
};
