import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rgba } from 'polished';

import { ColorScheme } from 'theme';
import { mq } from 'theme/media';

export const StyledDropdownMenu = styled(motion.nav)`
  position: absolute;
  width: 100%;
  height: 315px;
  overflow: hidden;
`;

export const StyledWrapper = styled.div`
  display: flex;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: ${({ theme }) => theme.spacing(6)};
    right: ${({ theme }) => theme.spacing(-5)};
    width: 1px;
    height: 222px;
    background-color: ${({ theme }) => rgba(theme.colors.violetGray, 0.25)};
  }
`;

export const StyledColumnWrapper = styled.div`
  display: block;
  margin: ${({ theme }) => theme.spacing(9, 0, 0, -2)};
`;

export const StyledRightColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing(11, 8, 11, 21)};
  height: 100%;
`;

export const StyledImageWrapper = styled.div`
  ${mq.xsLarge`
    position: relative;
    width: 521px;
    height: 314px;
    left: -38px;
    top: 2px;
    z-index: 1;

    &::after {
      content: '';
      width: 505px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.electricViolet};
      position: absolute;
      bottom: 66px;
      left: -20px;
      z-index: -1;
    }
  `}
`;

export const StyledTitleWrapper = styled.div<{ colorScheme: ColorScheme }>`
  max-width: 281px;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  z-index: 1;
  color: ${({ colorScheme, theme }) => (colorScheme === 'dark' ? theme.colors.white : theme.colors.blackRock)};
`;

export const StyledBackgroundRhombusWrapper = styled.div`
  position: relative;
  z-index: -1;
`;
