import React, { FC } from 'react';

import { StyledLabel, StyledTextArea, StyledWrapper, StyledOptionalLabel } from './TextArea.styles';

declare type ValidateResult = string | Array<string> | boolean | undefined;
declare type Validate = (data: any) => ValidateResult | Promise<ValidateResult>;

type TextAreaProps = {
  label: string;
  placeholder: string;
  name?: 'name' | 'phone' | 'email' | 'optional';
  variant?: 'onWhite' | 'onViolet';
  register?: (Ref: HTMLTextAreaElement, validateRule?: Validate | Record<string, Validate>) => void;
  margin?: string;
  isOptional?: boolean;
};

export const TextArea: FC<TextAreaProps> = ({
  label,
  placeholder,
  name,
  register,
  margin,
  variant = 'onViolet',
  isOptional = false
}) => (
  <StyledWrapper margin={margin}>
    <StyledLabel variant={variant}>
      {label} {isOptional ? <StyledOptionalLabel variant={variant}>(optional)</StyledOptionalLabel> : ''}
      <StyledTextArea name={name} placeholder={placeholder} ref={register} variant={variant} />
    </StyledLabel>
  </StyledWrapper>
);
