import getConfig from 'next/config';

import isUrlAbsolute from './isUrlAbsolute';

const {
  publicRuntimeConfig: { baseUrl }
} = getConfig();

export const isUrlExternal = (url: string): boolean => {
  if (!isUrlAbsolute(url)) {
    return false;
  }

  return new URL(url).origin !== baseUrl;
};
