import React, { FC, ReactNode } from 'react';

import Typography from 'components/atoms/Typography/Typography';
import Arrow from 'components/atoms/icons/Arrow/Arrow';

import { StyledArrowLink, StyledArrowWrapper } from './ArrowLink.styles';

export type ArrowLinkProps = {
  children: ReactNode | string;
};

const ArrowLink: FC<ArrowLinkProps> = ({ children }) => (
  <StyledArrowLink>
    <Typography variant="body2" weight="700">
      {children}
    </Typography>
    <StyledArrowWrapper>
      <Arrow />
    </StyledArrowWrapper>
  </StyledArrowLink>
);

export default ArrowLink;
