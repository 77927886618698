import React, { FC } from 'react';
import { PreviewBannerElement, PreviewBannerWrapper } from './PreviewBanner.style';

export const PreviewBanner: FC = () => (
  <PreviewBannerWrapper>
    <a href="/api/exit-preview">
      <PreviewBannerElement />
    </a>
  </PreviewBannerWrapper>
);
