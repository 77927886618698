import styled, { css, keyframes, DefaultTheme } from 'styled-components';
import { rgba, darken, lighten } from 'polished';

import { GenericProps } from './GenericButton';

const buttonVariants = (theme: DefaultTheme) => ({
  primary: {
    main: {
      background: theme.gradients.electricViolet,
      border: 'none',
      '&:hover:not([disabled])': {
        '&::after': { opacity: 1 }
      },
      '&:not([disabled])::after': {
        content: '""',
        position: 'absolute' as const,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        opacity: 0,
        'box-shadow': `2px 4px 10px 0px ${rgba(theme.colors.daisyBush, 0.32)}`,
        transition: 'opacity 500ms'
      },
      '&:active': {
        background: `linear-gradient(90deg, ${theme.colors.electricViolet} 23.12%, ${theme.colors.heliotrope2} 100%)`,
        '& div': {
          background: `linear-gradient(90deg, ${darken(0.05, theme.colors.electricViolet)} -1.51%, ${lighten(
            0.02,
            theme.colors.heliotrope2
          )} 250%)`
        }
      },
      '&:disabled': {
        background: theme.colors.whisper,
        '& span': {
          color: darken(0.2, theme.colors.whisper),
          opacity: 0.5
        },
        '& div': {
          background: theme.colors.whisper
        }
      }
    },
    colorBox: {
      background: `linear-gradient(90deg, ${darken(0.05, theme.colors.electricViolet)} 0%, ${lighten(
        0.05,
        theme.colors.heliotrope2
      )} 200%)`
    }
  },
  'secondary-white': {
    main: {
      background: 'transparent',
      border: `2px solid ${theme.colors.white}`,
      '&:hover': {
        border: `1px solid  ${theme.colors.white}`,
        background: rgba(theme.colors.white, 0.1)
      },
      '&:active': {
        border: `1px solid ${theme.colors.white}`,
        background: rgba(theme.colors.white, 0.3)
      },
      '&:disabled': {
        background: 'transparent',
        border: `1px solid ${rgba(theme.colors.white, 0.3)}`,
        '& span': {
          color: rgba(theme.colors.violetGray, 0.32)
        }
      }
    },
    colorBox: {}
  },
  'secondary-dark': {
    main: {
      background: 'transparent',
      border: `2px solid ${theme.colors.cornflowerBlue}`,
      '&:hover': {
        border: `1px solid ${theme.colors.cornflowerBlue}`,
        background: `linear-gradient(268.28deg, ${rgba(theme.colors.electricViolet, 0.1)} 3.87%, ${rgba(
          theme.colors.heliotrope2,
          0.1
        )} 92.97%)`
      },
      '&:active': {
        background: `linear-gradient(268.28deg, ${rgba(theme.colors.electricViolet, 0.24)} 3.87%, ${rgba(
          theme.colors.heliotrope2,
          0.24
        )} 92.97%)`
      },
      '&:disabled': {
        background: rgba(theme.colors.violetGray, 0.1),
        border: `1px solid ${rgba(theme.colors.violetGray, 0.1)}`,
        '& span': {
          color: rgba(theme.colors.violetGray, 0.2)
        }
      }
    },
    colorBox: {}
  },
  'tertiary-white': {
    main: {
      background: 'transparent',
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      '& span': {
        color: theme.colors.heliotropeContrast,
        textTransform: 'uppercase' as const,
        fontWeight: 800,
        fontSize: '10px',
        lineHeight: '14px'
      },
      '&:active': {
        background: `linear-gradient(268.28deg, ${rgba(theme.colors.electricViolet, 0.2)} 3.87%, ${rgba(
          theme.colors.heliotrope2,
          0.2
        )} 92.97%)`
      },
      '&:disabled': {
        background: 'transparent',
        '& span': {
          color: theme.colors.violetGray,
          fontWeight: 800,
          fontSize: '10px',
          lineHeight: '14px',
          textTransform: 'uppercase' as const,
          textDecoration: 'underline'
        }
      }
    },
    colorBox: {}
  },
  'tertiary-dark': {
    main: {
      background: 'transparent',
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      '& span': {
        color: 'white',
        textTransform: 'uppercase' as const,
        fontWeight: 800,
        fontSize: '10px',
        lineHeight: '14px'
      },
      '&:active': {
        background: `linear-gradient(268.28deg, ${rgba(theme.colors.electricViolet, 0.2)} 3.87%, ${rgba(
          theme.colors.heliotrope2,
          0.2
        )} 92.97%);`
      },
      '&:disabled': {
        background: 'transparent',
        '& span': {
          color: theme.colors.violetGray,
          fontWeight: 800,
          fontSize: '10px',
          lineHeight: '14px',
          textTransform: 'uppercase' as const,
          textDecoration: 'underline'
        }
      }
    },
    colorBox: {}
  }
});

export const onHover = keyframes`
  0% {
    width: 100%;
  }
  50% {
    transform: translateX(120%);
    width: 100%;
  }
  51% {
    transform: translateX(0);
    width: 0;
  }
  60% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

export const StyledUnderlineText = styled.span<Partial<GenericProps>>`
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    height: 1px;
    width: 100%;
    background: ${({ variant, theme }) =>
      variant === 'tertiary-white' ? theme.colors.heliotropeContrast : theme.colors.white};
  }
`;

export const StyledGenericContainer = styled.div<GenericProps>`
  ${({ theme, variant }) => css(buttonVariants(theme)[variant].main)};

  z-index: 2;
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  color: ${({ theme }) => theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  margin: ${({ margin }) => margin};
  padding: 0;
  display: flex;
  outline: none;
  user-select: none;
  border-radius: 0;

  &:hover {
    ${StyledUnderlineText} {
      &:after {
        animation: ${onHover} 1s;
      }
    }
  }
`;

export const StyledColorBox = styled.div<Partial<GenericProps>>`
  position: absolute;
  width: 30%;
  height: 120px;
  left: ${({ theme }) => theme.spacing(-1)};
  top: ${({ theme }) => theme.spacing(-6)};
  transform: rotate(45deg);

  ${({ variant, disabled, theme }) => !disabled && css(buttonVariants(theme)[variant].colorBox)};
`;

export const StyledWrapper = styled.div`
  overflow: hidden;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const StyledText = styled.span`
  position: relative;
`;
