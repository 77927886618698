import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Python: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M47.125 37.07H34.242c-4.379 0-8.058 3.75-8.058 8.008v5.887h-4.426c-3.754 0-5.938-2.727-6.852-6.54-1.23-5.124-1.183-8.187 0-13.097 1.024-4.285 4.305-6.539 8.059-6.539h17.73v-3.918H27.801v-2.633c0-3.726.988-5.742 6.441-6.707 3.871-.687 8.567-.722 12.883-.011 3.5.59 6.441 3.207 6.441 6.707V30.52c0 3.593-2.859 6.55-6.441 6.55zm-16.02-20.828c0 1.297 1.043 2.352 2.329 2.352 1.285 0 2.328-1.055 2.328-2.352s-1.055-2.36-2.328-2.36c-1.286 0-2.317 1.052-2.329 2.36zm0 0"
      fillRule="evenodd"
      fill={color}
    />
    <path
      opacity=".8"
      d="M62.25 26.742c3.754 0 5.516 2.813 6.441 6.54 1.293 5.175 1.352 9.058 0 13.1-1.3 3.923-2.687 6.54-6.441 6.54H42.926v3.973h12.87v2.578c0 3.715-3.194 5.609-6.44 6.55-4.887 1.41-8.805 1.192-12.883 0-3.403-1.003-6.442-3.054-6.442-6.55V47.19c0-3.535 2.918-6.55 6.442-6.55h12.882c4.293 0 8.06-3.739 8.06-8.18v-5.719zM46.738 61.676c0 1.297 1.043 2.351 2.328 2.351 1.286 0 2.329-1.054 2.329-2.351s-1.055-2.36-2.329-2.36c-1.285 0-2.316 1.051-2.328 2.36zm0 0"
      fillRule="evenodd"
      fill={color}
    />
  </svg>
);
export default withTheme(Python);
