import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const LinkedIn: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.7 0H2.3C1 0 0 1 0 2.3v27.4C0 31 1 32 2.3 32h27.4c1.3 0 2.3-1 2.3-2.3V2.3C32 1 31 0 29.7 0zM11.4 24.2h-4V12.5h4v11.7zm-2-13.3c-1.3 0-2.2-1-2.2-2 0-1.2.9-2 2.2-2 1.4 0 2.2.8 2.2 2 0 1-.8 2-2.2 2zm16 13.3h-3.9v-6.3c0-1.6-.6-2.6-2-2.6-1 0-1.7.7-2 1.4l-.1 1v6.5h-3.9V12.5h3.9V14c.5-.8 1.4-2 3.5-2 2.6 0 4.5 1.8 4.5 5.4v6.7z"
      fill={color}
    />
  </svg>
);

export default withTheme(LinkedIn);
