import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Adobe: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 12v56l25.45-56H9zm19.1 45.82h11.45L43.36 68h11.46L39.55 32.36 28.09 57.82zM44.63 12l25.45 56V12H44.64z"
      fill={color}
    />
  </svg>
);

export default withTheme(Adobe);
