import React, { FC } from 'react';

import { ColorScheme } from 'theme';
import { MobileLogo } from './MobileLogo';
import { DesktopLogo } from './DesktopLogo';

export type LogoProps = {
  colorScheme?: ColorScheme;
  wide?: boolean;
};

const Logo: FC<LogoProps> = ({ wide, colorScheme = 'dark' }) => (
  <>
    {wide && <MobileLogo colorScheme={colorScheme} />}
    <DesktopLogo variant={colorScheme} wide={wide} />
  </>
);

export default Logo;
