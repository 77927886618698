import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Firebase: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="11" y="7" width="25" height="33">
      <path
        d="M32.8851 14.3123C32.8385 14.0366 32.6408 13.8096 32.3725 13.7235C32.1042 13.6375 31.8098 13.7067 31.6091 13.9031L27.7249 17.7906L24.6475 12.0059C24.5241 11.7581 24.2695 11.6012 23.9907 11.6012C23.712 11.6012 23.4574 11.7581 23.334 12.0059L21.6639 15.168L17.4419 7.39292C17.1042 6.7605 16.1472 6.92791 16.0346 7.63473L11.9814 33.4151L22.8461 39.4603C23.5298 39.8324 24.3578 39.8324 25.0415 39.4603L36 33.4151L32.8851 14.3123Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M32.8851 14.3123C32.8385 14.0366 32.6408 13.8096 32.3725 13.7235C32.1042 13.6375 31.8098 13.7067 31.6091 13.9031L27.7249 17.7906L24.6475 12.0059C24.5241 11.7581 24.2695 11.6012 23.9907 11.6012C23.712 11.6012 23.4574 11.7581 23.334 12.0059L21.6639 15.168L17.4419 7.39292C17.1042 6.7605 16.1472 6.92791 16.0346 7.63473L11.9814 33.4151L22.8461 39.4603C23.5298 39.8324 24.3578 39.8324 25.0415 39.4603L36 33.4151L32.8851 14.3123Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M11.9814 33.4151L16.0533 7.63473C16.1659 6.92791 17.1229 6.7605 17.4607 7.39292L21.6639 15.168L23.334 12.0059C23.4631 11.7594 23.7199 11.6047 24.0001 11.6047C24.2803 11.6047 24.5371 11.7594 24.6662 12.0059L36 33.4151H11.9814Z"
        fill={color}
        fillOpacity="0.5"
      />
      <rect x="10.4803" y="13.5137" width="16.3251" height="20.6466" fill="url(#pattern0)" fillOpacity="0.14" />
      <path d="M26.0173 23.3689L21.6452 15.166L11.9814 33.4132L26.0173 23.3689Z" fill={color} fillOpacity="0.5" />
      <rect x="11.2309" y="12.0254" width="26.2703" height="28.6449" fill="url(#pattern1)" fillOpacity="0.1" />
      <path
        d="M36 33.4135L32.8851 14.3107C32.8385 14.035 32.6409 13.808 32.3725 13.7219C32.1042 13.6359 31.8099 13.7051 31.6091 13.9015L11.9815 33.4135L22.8461 39.4587C23.5298 39.8307 24.3579 39.8307 25.0416 39.4587L36 33.4135Z"
        fill={color}
      />
      <path
        opacity="0.2"
        d="M25.0415 39.2727C24.3578 39.6447 23.5298 39.6447 22.8461 39.2727L12.0002 33.2461L11.9814 33.4135L22.8461 39.4587C23.5298 39.8307 24.3578 39.8307 25.0415 39.4587L36 33.4135L35.9812 33.2461L25.0415 39.2727Z"
        fill={color}
        fillOpacity="0.5"
      />
      <path
        d="M32.8851 14.3123C32.8385 14.0366 32.6408 13.8096 32.3725 13.7235C32.1042 13.6375 31.8098 13.7067 31.6091 13.9031L27.7249 17.7906L24.6475 12.0059C24.5241 11.7581 24.2695 11.6012 23.9907 11.6012C23.712 11.6012 23.4574 11.7581 23.334 12.0059L21.6639 15.168L17.4419 7.39292C17.1042 6.7605 16.1472 6.92791 16.0346 7.63473L11.9814 33.4151L22.8461 39.4603C23.5298 39.8324 24.3578 39.8324 25.0415 39.4603L36 33.4151L32.8851 14.3123Z"
        fill="url(#paint1_linear)"
      />
    </g>
  </svg>
);

export default withTheme(Firebase);
