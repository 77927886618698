import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledTextContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  min-width: 246px;
  padding: ${({ theme }) => theme.spacing(11, 0, 13)};

  ${mq.large`
    padding: ${({ theme }) => theme.spacing(9, 0, 9)};
  `}
`;

export const StyledDescription = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  z-index: 2;

  ${mq.large`
    max-width: 320px;
  `}
`;

export const StyledButtonContainer = styled.div`
  z-index: 2;
`;

export const StyledTitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  z-index: 2;
`;
