type MousePositionType = {
  x: number;
  y: number;
};

export class CursorAnimation {
  readonly easeFactor = 0.15;
  readonly offset: MousePositionType = { x: -4, y: -5 };

  mousePosition: MousePositionType = { x: 0, y: 0 };
  currentPosition: MousePositionType = { x: 0, y: 0 };
  objRef: React.MutableRefObject<HTMLDivElement>;

  isPaused = true;
  isFollowing = false;

  constructor(ref: React.MutableRefObject<HTMLDivElement>) {
    this.objRef = ref;
  }

  handleMouseMove = (e: MouseEvent): void => {
    this.mousePosition = {
      x: e.clientX,
      y: e.clientY
    };
    if (!this.isPaused) {
      return;
    }
    this.isPaused = false;
    const moveMouseEase = () => {
      if (
        Math.round(this.currentPosition.x) === this.mousePosition.x &&
        Math.round(this.currentPosition.y) === this.mousePosition.y
      ) {
        this.isPaused = true;

        return;
      }

      this.currentPosition.x += (this.mousePosition.x - this.currentPosition.x) * this.easeFactor;
      this.currentPosition.y += (this.mousePosition.y - this.currentPosition.y) * this.easeFactor;

      if (this.objRef.current) {
        this.objRef.current.style.transform = `translate(${Math.round(
          this.currentPosition.x + this.offset.x
        )}px,${Math.round(this.currentPosition.y + this.offset.y)}px)`;
      }
      window.requestAnimationFrame(moveMouseEase);
    };

    moveMouseEase();
  };

  startFollowing(): void {
    if (!this.isFollowing) {
      window.addEventListener('mousemove', this.handleMouseMove);
      this.isFollowing = true;
    }
  }

  stopFollowing(): void {
    if (this.isFollowing) {
      window.removeEventListener('mousemove', this.handleMouseMove);
      this.isFollowing = false;
    }
  }
}
