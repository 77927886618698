import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Facebook: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.2 0H1.8C.8 0 0 .8 0 1.8v28.4c0 1 .8 1.8 1.8 1.8H17V19.6h-4.2v-4.8h4.2v-3.6c0-4.1 2.5-6.4 6.2-6.4L27 5v4.3h-2.5c-2 0-2.4 1-2.4 2.4v3h4.8l-.7 5h-4.1V32h8.1c1 0 1.8-.8 1.8-1.8V1.8c0-1-.8-1.8-1.8-1.8z"
      fill={color}
    />
  </svg>
);

export default withTheme(Facebook);
