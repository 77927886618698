import React, { FC } from 'react';

import { StyledArrow } from './Arrow.styles';

export type ArrowProps = {
  color?: string;
  direction?: 'left' | 'right' | 'down' | 'top';
  hasAnimation?: boolean;
  variant?: 'sm' | 'lg';
};

const Arrow: FC<ArrowProps> = ({ color = 'white', hasAnimation = false, direction = 'right', variant = 'lg' }) => (
  <StyledArrow
    hasAnimation={hasAnimation}
    direction={direction}
    width="8"
    height="8"
    viewBox={variant === 'lg' ? '0 0 8 8' : '0 0 8 12'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {variant === 'lg' ? (
      <path
        d="M0.480845 4.56151C0.513579 4.56682 0.546706 4.56928 0.579851 4.56885L6.22369 4.56885L6.10063 4.62608C5.98033 4.68302 5.8709 4.76051 5.77722 4.85504L4.19455 6.43772C3.98611 6.6367 3.95108 6.95679 4.11155 7.19614C4.29831 7.4512 4.65647 7.50658 4.91155 7.31982C4.93215 7.30472 4.95174 7.28826 4.97014 7.27055L7.83212 4.40857C8.05579 4.18516 8.05598 3.82275 7.83257 3.59908C7.83243 3.59894 7.83227 3.59878 7.83212 3.59863L4.97014 0.736656C4.7463 0.513439 4.38389 0.513939 4.16065 0.737782C4.14309 0.755401 4.12668 0.774147 4.11155 0.793895C3.95108 1.03325 3.98611 1.35334 4.19455 1.55232L5.77436 3.13786C5.85834 3.22193 5.9549 3.29242 6.06056 3.34678L6.23228 3.42405L0.611351 3.42405C0.318946 3.4132 0.0624053 3.61745 0.00747316 3.90487C-0.0431302 4.21691 0.1688 4.51089 0.480845 4.56151Z"
        fill={color}
      />
    ) : (
      <path
        d="M7.05888 5.99994C7.05888 6.14121 7.01648 6.28235 6.9388 6.3882L3.40949 11.6823C3.28244 11.8729 3.0706 12 2.8236 12H0.705965C0.317697 12 0 11.6823 0 11.294C0 11.1529 0.0423941 11.0118 0.120074 10.9059L3.3883 5.99994L0.120074 1.09411C0.0423941 0.988249 0 0.847108 0 0.705837C0 0.317698 0.317697 0 0.705965 0H2.8236C3.0706 0 3.28244 0.127053 3.40949 0.317698L6.9388 5.6118C7.01648 5.71765 7.05888 5.85879 7.05888 5.99994Z"
        fill={color}
      />
    )}
  </StyledArrow>
);

export default Arrow;
