import React, { FC } from 'react';

import {
  StyledFormSuccess,
  StyledFormSuccessInner,
  StyledFormSuccessInnerIcon,
  StyledFormSuccessInnerText,
  StyledFormInnerBox
} from './Success.styles';

export const Success: FC = () => (
  <StyledFormSuccess>
    <StyledFormSuccessInner>
      <StyledFormSuccessInnerIcon />
      <StyledFormSuccessInnerText>Thank you, your message has been sent.</StyledFormSuccessInnerText>
    </StyledFormSuccessInner>
    <StyledFormInnerBox />
  </StyledFormSuccess>
);

export default Success;
