import React, { FC, ElementType, MouseEvent } from 'react';

import Typography from 'components/atoms/Typography/Typography';

import {
  StyledColorBox,
  StyledText,
  StyledUnderlineText,
  StyledGenericContainer,
  StyledWrapper
} from './GenericButton.styles';

export type GenericProps = {
  margin?: string;
  variant?: 'primary' | 'secondary-white' | 'secondary-dark' | 'tertiary-white' | 'tertiary-dark';
  width?: string;
  height?: string;
  disabled?: boolean;
  as?: ElementType;
  href?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const GenericButton: FC<GenericProps> = ({
  children,
  margin,
  variant,
  width,
  height,
  disabled,
  as,
  href,
  onClick
}) => (
  <StyledGenericContainer
    variant={variant}
    margin={margin}
    width={width}
    height={height}
    disabled={disabled}
    as={as}
    href={href}
    onClick={onClick}
  >
    <StyledWrapper>
      {variant === 'primary' && <StyledColorBox variant={variant} />}
      {variant === 'tertiary-white' || variant === 'tertiary-dark' ? (
        <StyledUnderlineText variant={variant}>{children}</StyledUnderlineText>
      ) : (
        <StyledText>
          <Typography variant="caption">{children}</Typography>
        </StyledText>
      )}
    </StyledWrapper>
  </StyledGenericContainer>
);
