import { DocumentNode, gql } from '@apollo/client';

export const contactFormQuery = (): DocumentNode =>
  gql`
    {
      contactForm {
        title
        description
      }
    }
  `;

export const saveNewContactForm = (name: string, email: string, message: string, phone: string, metadata: string) =>
  gql`
    mutation {
      createNewContactForm(
        input: {
          data: {
            name: "${name}"
            email: "${email}"
            message: """${message}"""
            phone: "${phone}"
            accept: true
            metadata: "${metadata}"
          }
        }
      ) {
        newContactForm {
          id
        }
      }
    }
  `;
