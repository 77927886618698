import styled from 'styled-components';
import { motion } from 'framer-motion';
import { mq } from 'theme/media';

export const StyledPolicyOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.transparentHalfBlack};
  z-index: 6;
`;

export const StyledPolicyPopup = styled(motion.div)`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
`;

export const StyledPolicyPopupWraper = styled.div`
  position: relative;
  max-width: 600px;
  width: calc(100% - 5px);
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.white};

  ${mq.large`
    max-width: 1000px;
  `}
`;

export const StyledPolicyPopupButton = styled.div`
  position: absolute;
  top: 12px;
  right: 23px;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 0;
  transition: opacity 0.3s;
  opacity: 0.5;
  cursor: pointer;
  outline: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.cornflowerBlue};

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 15px;
    background-color: ${(props) => props.theme.colors.white};
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 15px;
    background-color: ${(props) => props.theme.colors.white};
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

export const StyledPolicyPopupContainer = styled.div``;

export const StyledPolicyPopupScrollable = styled.div`
  overflow: hidden auto;
  max-height: 85vh;
`;

export const StyledPolicyPopupScrollAnchor = styled.div`
  height: 1px;
  opacity: 0;
`;

export const StyledPolicyPopupScrollHead = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  min-height: 50px;
  padding: 12px 52px 14px 12px;
  border-bottom: 1px solid ${(props) => props.theme.colors.transparentShuttleGray};
`;

export const StyledPolicyPopupScrollHeadTitle = styled.div`
  margin: 0;
  color: ${(props) => props.theme.colors.black};
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledPolicyPopupScrollBody = styled.div`
  color: ${(props) => props.theme.colors.black};
  min-height: 60px;
`;
