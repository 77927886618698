import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Angular: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.0517 24.0949V15.0381L17.8984 28.6437L23.7177 79.0912L56.0517 96.9999V66.078H45.8754L41.0806 78.0444H32.1878L56.0386 24.5643L56.0517 24.5936V24.1358V24.0949ZM56.0517 41.9715L56.0386 41.9402L49.0719 58.7014H56.0517V41.9715Z"
      fill={color}
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.2049 28.6437L56.0517 15.0381L17.8984 28.6437L23.7177 79.0912L56.0517 96.9999L88.3856 79.0912L94.2049 28.6437ZM32.1878 78.0444L56.0386 24.5643L79.8076 78.0444H70.9147L66.1199 66.078H45.8754L41.0806 78.0444H32.1878ZM49.0719 58.7014H63.0054L56.0386 41.9402L49.0719 58.7014Z"
      fill={color}
      fillOpacity="0.6"
    />
  </svg>
);

export default withTheme(Angular);
