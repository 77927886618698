import React, { FC, useEffect } from 'react';
import TermsConditions from 'components/pages/TermsConditions/TermsConditions';

import { usePolicy } from 'utils/queries/termsAndConditions';
import { fadeInOut } from 'theme/animations';

import {
  StyledPolicyOverlay,
  StyledPolicyPopup,
  StyledPolicyPopupWraper,
  StyledPolicyPopupButton,
  StyledPolicyPopupContainer,
  StyledPolicyPopupScrollable,
  StyledPolicyPopupScrollAnchor,
  StyledPolicyPopupScrollHead,
  StyledPolicyPopupScrollHeadTitle,
  StyledPolicyPopupScrollBody
} from './Policy.styles';

type PolicyContentProps = {
  hidePolicy: () => void;
  title: string;
  content: string;
};

export const PolicyContent: FC<PolicyContentProps> = ({ hidePolicy, title, content }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = null;
    };
  }, []);

  return (
    <StyledPolicyOverlay
      key="policy-overlay"
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={fadeInOut}
      onClick={() => hidePolicy()}
    >
      <StyledPolicyPopup key="policy-popup" initial="hidden" animate="visible" exit="hidden" variants={fadeInOut}>
        <StyledPolicyPopupWraper onClick={(e) => e.stopPropagation()}>
          <StyledPolicyPopupButton onClick={() => hidePolicy()} />
          <StyledPolicyPopupContainer>
            <StyledPolicyPopupScrollable>
              <StyledPolicyPopupScrollAnchor />
              <StyledPolicyPopupScrollHead>
                <StyledPolicyPopupScrollHeadTitle />
              </StyledPolicyPopupScrollHead>
              <StyledPolicyPopupScrollBody>
                <TermsConditions title={title} content={content} />
              </StyledPolicyPopupScrollBody>
              <StyledPolicyPopupScrollAnchor />
            </StyledPolicyPopupScrollable>
          </StyledPolicyPopupContainer>
        </StyledPolicyPopupWraper>
      </StyledPolicyPopup>
    </StyledPolicyOverlay>
  );
};

type PolicyProps = {
  hidePolicy: () => void;
};

export const Policy: FC<PolicyProps> = ({ hidePolicy }) => {
  const page = usePolicy();

  return page && <PolicyContent hidePolicy={hidePolicy} title={page.title} content={page.content} />;
};

export default Policy;
