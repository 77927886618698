import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import { mq } from 'theme/media';
import fcs from 'theme/force-color-scheme';
import theme from './theme';

export const GlobalStyle = createGlobalStyle`
  ${reset};
  
  *, *:before, *:after {
    box-sizing: border-box;
  } 

  strong {
    font-weight: bold;
  }

  body {
    font-family: 'NunitoSans', sans-serif;
    font-weight: 400;
    margin: 0;
    font-size: 14px;
    line-height: normal;
    overflow-x: hidden;
    ${fcs`
      background-color: ${theme.colors.white};
      color: ${theme.colors.blackRock};
    `}
  } 

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    font-family: inherit;
    line-height: 0;
  }

  input, textarea {
    font-family: inherit;
  }
  
  #__next {
    box-sizing: border-box;
    margin-top: ${({ theme }) => theme.spacing(7)};

    ${mq.xsLarge`
      margin-top: ${({ theme }) => theme.spacing(11)};
    `}
  }
`;
