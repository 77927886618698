import styled, { css } from 'styled-components';

import { TypographyProps } from './Typography';

export const StyledTypography = styled.span<{
  color: TypographyProps['color'];
  weight: TypographyProps['weight'];
  align: TypographyProps['align'];
  ellipsisLines: TypographyProps['ellipsisLines'];
  transform: TypographyProps['transform'];
}>`
  display: inline-block;
  letter-spacing: 0.01em;

  ${({ theme, color }) =>
    color !== 'inherit' &&
    css`
      color: ${color === 'primary' ? theme.colors.blackRock : theme.colors.white};
    `}

  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${weight};
    `}

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ transform }) =>
    transform &&
    css`
      text-transform: ${transform};
    `}

    ${({ ellipsisLines }) =>
    ellipsisLines &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${ellipsisLines};
      -webkit-box-orient: vertical;
      overflow: hidden;

      ${ellipsisLines === 1 &&
      css`
        word-break: break-all;
      `}
    `}
`;

export const StyledH1 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h1};
`;

export const StyledH2 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h2};
`;

export const StyledH3 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h3};
`;

export const StyledH4 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h4};
`;

export const StyledBody1 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.body1};
`;

export const StyledBody2 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.body2};
`;

export const StyledCaption = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.caption};
`;

export const StyledSubtitle = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.subtitle};
`;

export const StyledLabel = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.label};
`;
