import React, { ComponentType } from 'react';
import { useRouter } from 'next/router';

type RequiredProps = {
  active?: boolean;
  href: string;
};

export const withActivePath =
  <P extends RequiredProps>(Component: ComponentType<P>): ComponentType<Omit<P, 'active'>> =>
  (props: P): JSX.Element => {
    const router = useRouter();

    return <Component {...props} active={router.pathname === props.href} />;
  };

export default withActivePath;
