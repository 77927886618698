import React, { FC, useRef, useEffect } from 'react';

import { Rhombus } from 'components/atoms/Geometric/Rhombus/Rhombus';

import { CursorAnimation } from 'helpers/cursorAnimation';
import useMedia from 'hooks/useMedia';

import { StyledCursorContainer, StyledRhombusWrapper } from './Cursor.styles';

export const Cursor: FC = () => {
  const rhombusRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useMedia();

  useEffect(() => {
    if (!isMobile) {
      const cursorAnimation = new CursorAnimation(rhombusRef);
      cursorAnimation.startFollowing();

      return () => {
        cursorAnimation.stopFollowing();
      };
    }
  }, [isMobile]);

  return (
    <StyledCursorContainer>
      <StyledRhombusWrapper ref={rhombusRef} visible={!isMobile}>
        <Rhombus width="7px" variant="purple" />
      </StyledRhombusWrapper>
    </StyledCursorContainer>
  );
};
