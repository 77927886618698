import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Design: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" width="80" height="80">
    <g fill={color}>
      <path d="M56.4726 14.912l8.1185 3.9068c.748.3593 1.0617 1.2564.7023 2.0046L63.341 24.882 57.927 22.278c-.7419-.3594-1.6425-.0438-2.0047.7024-.3593.7481-.0453 1.6455.7024 2.0045l5.4138 2.6045v.0001l-1.3019 2.7058-.0005.0004.0002-.0003-5.4139-2.6045c-.7407-.3579-1.641-.0423-2.0046.7023-.3594.748-.0454 1.6456.7023 2.005l5.414 2.6044.0002-.0006.0001.0006-1.5774 2.7117H45l9.468-20.1c.1729-.3593.481-.635.8564-.7656.3739-.1361.786-.1115 1.1482.0633z" />
      <path d="M32.8571 35.7143v-9.2857h-9.2857v9.2857h9.2857z" fillOpacity=".7" />
      <path d="M32.8571 35.7143v-9.2857h-4.2857v9.2857h4.2857z" fillOpacity=".7" />
      <path
        d="M32.8571 26.4286v-1.5179c0-.1213-.031-.3644-.093-.4858l-3.0953-9.1071c-.1854-.6072-.7738-1.032-1.4545-1.032-.6807 0-1.2692.4248-1.4545 1.032l-3.0953 9.1071c-.062.1214-.093.3645-.093.4858v1.5179h9.2856z"
        fillOpacity=".8"
      />
      <path d="M35.7143 15.7143H45v20h-9.2857v-20z" fillOpacity=".8" />
      <path d="M35.7143 29.2857h5v1.4286h-5v-1.4286zM35.7143 32.8571h5v1.4286h-5v-1.4286z" fillOpacity=".7" />
      <path
        d="M42.1429 29.2857h1.4285v1.4286H42.143v-1.4286zM42.1429 32.8571h1.4285v1.4286H42.143v-1.4286z"
        fillOpacity=".5"
      />
      <path d="M35.7143 22.1429h5v1.4285h-5V22.143z" fillOpacity=".8" />
      <path d="M42.1429 22.1429h1.4285v1.4285H42.143V22.143z" fillOpacity=".5" />
      <path d="M35.7143 25.7143h5v1.4286h-5v-1.4286z" fillOpacity=".8" />
      <path d="M42.1429 25.7143h1.4285v1.4286H42.143v-1.4286z" fillOpacity=".5" />
      <path d="M35.7143 18.5714h5V20h-5v-1.4286z" fillOpacity=".8" />
      <path d="M42.1429 18.5714h1.4285V20H42.143v-1.4286z" fillOpacity=".5" />
      <path
        d="M65.3832 36.2804c-.3013-.3573-.7232-.5661-1.1752-.5661H22.0198c-.693 0-1.4263.477-1.577 1.1621l-6.1273 27.02c-.0906.447.0302.894.3014 1.2517.3012.3574.7231.5662 1.1751.5662h42.3891c.693 0 1.3258-.477 1.4765-1.1622l6.0269-27.0199c.0906-.447-.0302-.894-.3013-1.2518z"
        fillOpacity=".5"
      />
      <path
        d="M59.6339 64.5521l6.0505-27.0199c.091-.447-.0303-.894-.3025-1.2518-.3025-.3573-.726-.5661-1.1798-.5661H40v30h18.1516c.6956 0 1.331-.477 1.4823-1.1622zm-15.7055-13.941H55.942c.8412 0 1.5017.6605 1.5017 1.5016v6.0068c0 .8408-.6605 1.5016-1.5017 1.5016H43.9284c-.8411 0-1.5016-.6608-1.5016-1.5016v-6.0068c0-.8411.6605-1.5016 1.5016-1.5016z"
        fillOpacity=".9"
      />
    </g>
  </svg>
);

export default withTheme(Design);
