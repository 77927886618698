import { css } from 'styled-components';

import { mq } from 'theme/media';

const fonts = {
  h1: css`
    font-size: 40px;
    line-height: 48px;

    ${mq.large`  
      font-size: 56px;
      line-height: 64px;
    `}
  `,
  h2: css`
    font-size: 40px;
    line-height: 48px;
  `,
  h3: css`
    font-size: 32px;
    line-height: 40px;
  `,
  h4: css`
    font-size: 24px;
    line-height: 32px;
  `,
  body1: css`
    font-size: 20px;
    line-height: 32px;
  `,
  body2: css`
    font-size: 16px;
    line-height: 24px;
  `,
  caption: css`
    font-size: 14px;
    line-height: 20px;
  `,
  subtitle: css`
    font-size: 12px;
    line-height: 20px;
  `,
  label: css`
    font-size: 10px;
    line-height: 18px;
  `,
  counter: css`
    font-size: 20px;
    line-height: 64px;
    font-weight: 800;
  `,
  widget: {
    'widget-font-family-default': 'Helvetica Neue, Helvetica, Arial, sans-serif',
    'widget-font-family': 'Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif'
  }
};

export default fonts;
