import styled, { css, DefaultTheme } from 'styled-components';

import { HexagonProps } from './Hexagon';

const hexagonVariant = (theme: DefaultTheme) => ({
  purple: {
    background: theme.gradients.purple
  },
  blue: {
    background: `linear-gradient(90deg, ${theme.colors.daisyBush} 0%, ${theme.colors.malibu} 100%)`
  },
  prelude: {
    background: theme.colors.violetGray
  },
  white: {
    background: theme.colors.white
  },
  whisper: {
    background: theme.colors.whisper
  },
  transparent: {
    background: 'transparent'
  }
});

export const StyledHexagon = styled.div<HexagonProps>`
  position: ${({ position }) => position || 'absolute'};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  z-index: ${({ zIndex }) => zIndex || 0};
  opacity: ${({ opacity }) => opacity};

  ${({ variant, theme }) => hexagonVariant(theme)[variant]};

  ${({ rotate }) =>
    rotate
      ? css<{ width: HexagonProps['width'] }>`
          width: ${({ width }) => width};
          height: calc(${({ width }) => width} * 0.865);
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          transform: rotate(${rotate});
        `
      : css<{ width: HexagonProps['width'] }>`
          height: ${({ width }) => width};
          width: calc(${({ width }) => width} * 0.865);
          clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
          transform: unset;
        `};
`;

export const StyledChildrenWrapper = styled.div<{
  rotate: HexagonProps['rotate'];
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(-${rotate});
    `};
`;

export type { HexagonProps };
