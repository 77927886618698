import React from 'react';
import { MDXRemote, MDXRemoteSerializeResult } from 'next-mdx-remote';
import dynamic from 'next/dynamic';

import fromCMS from 'utils/fromCMS';
import isUrlAbsolute from 'utils/isUrlAbsolute';
import { isUrlExternal } from 'utils/isUrlExternal';
import breakpoints from 'theme/breakpoints';
import { getNextImgUrl } from 'helpers/image';

type MarkedProps = {
  children?: string | MDXRemoteSerializeResult | null;
};

const components = {
  a: ({ href, ...props }) => {
    if (isUrlExternal(href)) {
      return <a {...props} href={href} target="_blank" rel="noreferrer noopener nofollow" />;
    }

    return <a {...props} href={href} />;
  },
  code: dynamic(() => import('components/CodeBlock/CodeBlock').then((mod) => mod.CodeBlock)),
  img: ({ src, ...props }) => {
    if (isUrlAbsolute(src)) {
      return <img {...props} loading="lazy" decoding="async" />;
    }

    const baseSrc = fromCMS(src);
    const sources = Object.values(breakpoints)
      .map((size) => `${getNextImgUrl(baseSrc, { width: size })} ${size}w`)
      .join(', ');

    return <img {...props} src={baseSrc} srcSet={sources} sizes="100vw" loading="lazy" decoding="async" />;
  },
  EmailContactBanner: dynamic(() =>
    import('components/molecules/EmailContactBanner/EmailContactBanner').then((mod) => mod.EmailContactBanner)
  )
};

export const Marked = ({ children }: MarkedProps) => {
  if (!children) {
    return null;
  }

  // Escape hatch to display Terms and Conditions without serializing them on the frontend
  if (typeof children === 'string') {
    return <div dangerouslySetInnerHTML={{ __html: children }} />;
  }

  return <MDXRemote {...children} components={components} />;
};
