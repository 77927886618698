import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';

import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

import { StyledLabel, StyledInput, StyledWrapper, StyledOptionalLabel } from './Input.styles';

declare type ValidateResult = string | Array<string> | boolean | undefined;
declare type Validate = (data: any) => ValidateResult | Promise<ValidateResult>;

type InputProps = {
  label: string;
  placeholder: string;
  name?: 'name' | 'phone' | 'email' | 'optional';
  variant?: 'onWhite' | 'onViolet';
  error?: FieldError;
  register?: (Ref: HTMLInputElement, validateRule?: Validate | Record<string, Validate>) => void;
  margin?: string;
  isOptional?: boolean;
};

export const Input: FC<InputProps> = ({
  label,
  error,
  placeholder,
  name,
  register,
  margin,
  variant = 'onViolet',
  isOptional = false
}) => (
  <StyledWrapper margin={margin} isError={!!error}>
    <StyledLabel isError={!!error} variant={variant}>
      {label} {isOptional ? <StyledOptionalLabel variant={variant}>(optional)</StyledOptionalLabel> : ''}
      <StyledInput name={name} placeholder={placeholder} ref={register} isError={!!error} variant={variant} />
    </StyledLabel>
    <ErrorMessage error={error} />
  </StyledWrapper>
);
