import React, { FC, ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';

import { scaleUp } from 'theme/animations';

import { StyledRhombusContainer, StyledRhombusShape, StyledChildrenWrapper } from './Rhombus.styles';

export type RhombusProps = {
  id?: string;
  width: string;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  rotate?: string;
  isRelative?: boolean;
  hasShadow?: boolean;
  isScaleAnimated?: boolean;
  variant?:
    | 'purple'
    | 'blue'
    | 'violet-gray'
    | 'white'
    | 'transparent-purple'
    | 'transparent-white'
    | 'ghost'
    | 'ghostThick'
    | 'dashed'
    | 'whisper';
  moveDistance?: string;
  moveDuration?: string;
  moveDelay?: string;
  zIndex?: number;
  opacity?: number;
  hasClip?: boolean;
  children?: ReactNode;
};

export const Rhombus: FC<RhombusProps> = ({
  width,
  top,
  left,
  right,
  bottom,
  variant = 'purple',
  rotate,
  isRelative = false,
  hasShadow = false,
  isScaleAnimated = false,
  moveDistance,
  moveDuration,
  moveDelay,
  opacity,
  hasClip = false,
  children,
  zIndex
}) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.75 });

  return (
    <StyledRhombusContainer
      ref={ref}
      animate={isScaleAnimated && (inView ? 'visible' : 'hidden')}
      variants={scaleUp}
      width={width}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      isRelative={isRelative}
      moveDistance={moveDistance}
      moveDuration={moveDuration}
      moveDelay={moveDelay}
      opacity={opacity}
      zIndex={zIndex}
    >
      <StyledRhombusShape variant={variant} rotate={rotate} hasShadow={hasShadow} hasClip={hasClip}>
        {children && <StyledChildrenWrapper rotate={rotate}>{children}</StyledChildrenWrapper>}
      </StyledRhombusShape>
    </StyledRhombusContainer>
  );
};
