import styled from 'styled-components';

export const WidgetButtonInnerContainer = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: relative;
  display: inline-block;
  transform: scale(0.85);
  transition: transform 0.3s;
`;

export const WidgetButtonInnerMask = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: absolute;
  top: -8px;
  left: -8px;
  height: 82px;
  min-width: 66px;
  width: calc(100% + 16px);
  border-radius: 100px;
  opacity: 0.2;
  background: ${(props) => props.theme.colors.cornflowerBlue};
`;

export const WidgetButtonBlock = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  width: 66px;
  height: 66px;
  border-radius: 100%;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
`;

export const WidgetButtonPulse = styled.div`
  margin: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.cornflowerBlue};
  animation: widgetPulse infinite 1.5s;
`;

export const WidgetButtonInnerBlock = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: relative;
  display: flex;
  height: 66px;
  border-radius: 100px;
  background: ${(props) => props.theme.colors.cornflowerBlue};
  box-sizing: border-box;
`;

export const WidgetButtonIconContainer = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: relative;
  flex: 1;
`;

export const WidgetButtonInnerItem = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: opacity 0.6s ease-out;
  animation: socialRotateBack 0.4s;
  overflow: hidden;
`;
