import React, { FC } from 'react';

import { Inputs as Form } from 'components/ContactForm/Inputs/Inputs';

import { revealRightFast, revealBottomRightFast, fadeInOut } from 'theme/animations';

import {
  AnimatedWindowPanel,
  WindowButton,
  WindowContent,
  WindowScrollable,
  WindowScroll,
  WindowWrapper,
  WindowHeader,
  WindowFormContent,
  WindowOverlay
} from './WindowWidget.styles';

type WindowProps = {
  hideWindow: () => any;
  isMobile: boolean;
};

export const WindowWidget: FC<WindowProps> = ({ hideWindow, isMobile }) => (
  <>
    <WindowOverlay
      onClick={() => hideWindow()}
      key="overlay"
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={fadeInOut}
    />
    <AnimatedWindowPanel
      key="window-panel"
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={isMobile ? revealBottomRightFast : revealRightFast}
    >
      <WindowButton onClick={() => hideWindow()} />
      <WindowContent>
        <WindowScrollable>
          <WindowScroll />
          <WindowWrapper>
            <WindowHeader />
            <WindowFormContent>
              <Form variant="onWhite" />
            </WindowFormContent>
            <WindowScroll />
          </WindowWrapper>
          <WindowScroll />
        </WindowScrollable>
      </WindowContent>
    </AnimatedWindowPanel>
  </>
);

export default WindowWidget;
