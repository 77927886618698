export type MenuVisibility = 'Always' | 'DesktopOnly' | 'MobileOnly';

export type MenuItem = {
  href: string;
  text: string;
  animation: number;
  id: string;
  title: string;
  sort: number;
  buttonName: string;
  buttonHref: string;
  image: {
    alternativeText: string | null;
    url: string;
    width: number;
    height: number;
  };
  menu: { parentId: string } | null;
  visibility: MenuVisibility | null;
};

export type MenuStructure = Array<MenuItem>;

export const isMobileVisible = ({ visibility }: MenuItem): boolean => visibility !== 'DesktopOnly';

export const isDesktopVisible = ({ visibility }: MenuItem): boolean => visibility !== 'MobileOnly';
