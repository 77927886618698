import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const AdobeAi: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 12h49c2 0 3.5 1.6 3.5 3.5v49c0 2-1.6 3.5-3.5 3.5h-49c-2 0-3.5-1.6-3.5-3.5v-49c0-2 1.6-3.5 3.5-3.5zm7.9 40.2l8-28h8l8.5 28h-5.4l-2.6-7.7h-8.8l-2.5 7.7h-5.2zM34 34L32 40.7H39L36.9 34l-1.2-4.2-.2-1h-.1l-.5 1.8c-.2 1.1-.5 2.3-.9 3.4zm21 18.2h-5V32.5h5v19.7zm.4-25.2c0 1.5-1.2 2.6-3 2.6-1.6 0-2.8-1.1-2.8-2.6 0-1.6 1.2-2.8 3-2.8 1.6 0 2.7 1.2 2.8 2.8z"
      fill={color}
    />
  </svg>
);

export default withTheme(AdobeAi);
