import React, { ComponentType, FC } from 'react';

import Adobe from '../Technologies/Adobe';
import AdobeAi from '../Technologies/AdobeAi';
import AdobeXd from '../Technologies/AdobeXd';
import Android from '../Technologies/Android';
import Angular from '../Technologies/Angular';
import CSS from '../Technologies/CSS';
import Figma from '../Technologies/Figma';
import Html from '../Technologies/Html';
import InLogo from '../Technologies/InLogo';
import Ios from '../Technologies/Ios';
import Javascript from '../Technologies/Javascript';
import Node from '../Technologies/Node';
import Photoshop from '../Technologies/Photoshop';
import Python from '../Technologies/Python';
import ReactComponent from '../Technologies/React';
import RubyOnRails from '../Technologies/RubyOnRails';
import Sketch from '../Technologies/Sketch';
import Zeplin from '../Technologies/Zeplin';
import Design from '../Technologies/Design';
import Devops from '../Technologies/Devops';
import Facebook from '../Socials/Facebook';
import LinkedIn from '../Socials/LinkedIn';
import Twitter from '../Socials/Twitter';
import Dimension from '../Technologies/Dimension';
import Firebase from '../Technologies/Firebase';
import Loader from '../Widget/Loader';
import Button from '../Widget/Button';

export type Icons =
  | 'adobe'
  | 'adobeAi'
  | 'adobeXd'
  | 'android'
  | 'angular'
  | 'css'
  | 'design'
  | 'devops'
  | 'dimension'
  | 'facebook'
  | 'figma'
  | 'firebase'
  | 'html'
  | 'inLogo'
  | 'ios'
  | 'javascript'
  | 'linkedin'
  | 'node'
  | 'photoshop'
  | 'python'
  | 'react'
  | 'rubyOnRails'
  | 'sketch'
  | 'twitter'
  | 'zeplin'
  | 'loader'
  | 'button';

export type IconResolverProps = {
  id: Icons;
  color?: string;
};

const componentsMap: { [key in Icons]: ComponentType } = {
  adobe: Adobe,
  adobeAi: AdobeAi,
  adobeXd: AdobeXd,
  android: Android,
  angular: Angular,
  css: CSS,
  design: Design,
  devops: Devops,
  dimension: Dimension,
  facebook: Facebook,
  figma: Figma,
  firebase: Firebase,
  html: Html,
  inLogo: InLogo,
  ios: Ios,
  javascript: Javascript,
  linkedin: LinkedIn,
  node: Node,
  photoshop: Photoshop,
  python: Python,
  react: ReactComponent,
  rubyOnRails: RubyOnRails,
  sketch: Sketch,
  twitter: Twitter,
  zeplin: Zeplin,
  loader: Loader,
  button: Button
};

const IconResolver: FC<IconResolverProps> = ({ id, ...props }) => {
  const Component = componentsMap[id];

  return <Component {...props} />;
};

export default IconResolver;
