import styled from 'styled-components';

const SIZE = 160;
const TEXT_X_OFFSET = 16;
const TEXT_Y_OFFSET = 8;

export const PreviewBannerWrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: ${SIZE}px;
  height: ${SIZE}px;
  filter: drop-shadow(1px 6px 3px rgba(50, 50, 0, 0.5));
`;

export const PreviewBannerElement = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.candlelight};
  clip-path: polygon(100% 0, 0 100%, 0 0);
  cursor: pointer;

  &:hover {
    &::after {
      transform: translate(calc(${SIZE * 0.42 - TEXT_X_OFFSET}px - 50%), calc(${SIZE * 0.42 - TEXT_Y_OFFSET}px - 50%))
        rotate(-45deg) scale(1);
    }
  }

  &::after {
    content: 'Exit';
    position: absolute;
    top: 0;
    left: 0;
    color: ${({ theme }) => theme.colors.blackRock};
    text-transform: uppercase;
    transform: translate(calc(${SIZE * 0.42 - TEXT_X_OFFSET}px - 50%), calc(${SIZE * 0.42 - TEXT_Y_OFFSET}px - 50%))
      rotate(-45deg) scale(0);
    transition: transform 100ms ease-in-out;
  }

  &::before {
    content: 'Preview';
    position: absolute;
    color: ${({ theme }) => theme.colors.blackRock};
    text-transform: uppercase;
    top: 0;
    left: 0;
    display: block;
    transform: translate(calc(${SIZE / 2 - TEXT_X_OFFSET}px - 50%), calc(${SIZE / 2 - TEXT_Y_OFFSET}px - 50%))
      rotate(-45deg);

    ${({ theme }) => theme.fonts.body1}
  }
`;
