import React, { FC } from 'react';

import { Rhombus } from 'components/atoms/Geometric/Rhombus/Rhombus';
import { ColorScheme } from 'theme';

import { StyledBackgroundRhombusWrapper } from './DropdownMenu.styles';

export type GeoShapesProps = {
  colorScheme?: ColorScheme;
};

export const GeoShapes: FC<GeoShapesProps> = ({ colorScheme = 'light' }) => (
  <>
    <Rhombus width="7px" variant="purple" bottom="33px" left="calc(50% - 16px)" moveDistance="60px" moveDuration="4s" />
    <StyledBackgroundRhombusWrapper>
      <Rhombus width="156px" variant="white" opacity={colorScheme === 'dark' ? 0.05 : 1} top="85px" left="283px" />
    </StyledBackgroundRhombusWrapper>
    <Rhombus
      width="12px"
      variant="purple"
      top="72px"
      right="78px"
      moveDistance="40px"
      moveDuration="4s"
      moveDelay="0.25s"
    />
    <Rhombus
      width="7px"
      variant="purple"
      top="53px"
      right="39px"
      moveDistance="30px"
      moveDuration="4s"
      moveDelay="0.5s"
    />
  </>
);
