import React, { FC } from 'react';
import { withTheme } from 'styled-components';
import { darken } from 'polished';

import { IconProps } from 'components/atoms/icons/Icon';

const Zeplin: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.8566 57.2928C41.6783 56.7579 41.5 56.1339 41.3217 55.599C39.8954 50.9633 38.8256 44.6338 38.2016 37.9477C37.2209 25.6453 38.2016 12.3622 42.0349 12.0056C44.1745 11.8273 45.8683 15.9281 46.9381 19.3158C48.3644 23.9515 49.4342 30.281 50.0582 36.9671C51.0389 49.2695 50.0582 62.5526 46.2249 62.9091C44.3528 62.9983 42.8373 60.2347 41.8566 57.2928ZM45.5117 17.8894C44.1745 14.1452 42.8373 12.808 42.0349 12.8971C39.6279 13.0754 37.845 23.4166 39.093 37.8586C39.6279 44.5447 40.6977 50.6959 42.1241 55.3316C43.6396 60.413 45.2442 62.1068 46.1357 62.0177C48.5427 61.8394 50.3257 51.4982 49.0776 37.0562C48.5427 30.3701 47.4729 24.2189 46.0466 19.5832C45.8683 18.9592 45.69 18.3351 45.5117 17.8894Z"
      fill={darken(0.2, color)}
    />
    <path
      d="M29.7326 23.5064C28.6628 20.5645 28.0388 17.4444 29.5543 16.3746C32.6745 14.1459 41.9459 23.7739 48.9886 33.9368C52.822 39.4639 55.9421 44.9911 57.8142 49.5377C59.1515 52.8361 60.4887 57.1152 58.7057 58.2742C55.5855 60.5029 46.3141 50.8749 39.2714 40.712C35.3489 35.274 32.2288 29.7468 30.3566 25.2002C30.0892 24.6654 29.9109 24.1305 29.7326 23.5064ZM57.6359 51.5881C57.4576 51.0532 57.2794 50.5183 57.0119 49.8943C55.1398 45.4369 52.0196 39.9988 48.2754 34.4716C39.9846 22.5258 32.0505 15.7505 30.0892 17.0878C29.376 17.6227 29.2869 19.9405 31.2481 24.7545C33.1202 29.2119 36.2404 34.6499 39.9846 40.1771C48.2754 52.123 56.2096 58.8982 58.1708 57.561C58.7949 57.2044 58.9732 55.3323 57.6359 51.5881Z"
      fill={darken(0.2, color)}
    />
    <path
      d="M29.3762 42.8506L13.6861 48.4669L13.4187 58.6298L22.9575 55.153L32.5855 51.6762L29.3762 42.8506Z"
      fill={darken(0.02, color)}
    />
    <path
      d="M26.1668 33.9365L16.5388 37.3241L7 40.8009L13.6861 48.4677L29.3762 42.8513L26.1668 33.9365Z"
      fill={darken(0.2, color)}
    />
    <path
      d="M72.167 27.429C74.3066 33.2236 63.6088 42.4058 48.3645 47.933C33.1202 53.371 19.0348 53.1036 16.8953 47.309C19.9263 55.8672 34.814 58.3633 50.0583 52.8361C65.3918 47.309 75.2872 35.9872 72.167 27.429Z"
      fill={darken(0.2, color)}
    />
    <path
      d="M48.3645 47.933C63.6088 42.4058 74.3066 33.3127 72.167 27.429L16.8953 47.309C19.0348 53.1036 33.1202 53.371 48.3645 47.933Z"
      fill={darken(0.1, color)}
    />
    <path
      d="M40.7868 26.804C56.0311 21.2768 70.1165 21.6334 72.256 27.428C69.225 18.8698 54.3373 16.3737 39.093 21.9009C23.8487 27.428 13.8641 38.7498 16.8951 47.3081C14.8447 41.4243 25.4533 32.242 40.7868 26.804Z"
      fill={color}
    />
    <path
      d="M40.7874 26.8044C25.5431 32.3316 14.8453 41.4247 16.9848 47.3085L72.2566 27.4285C70.1171 21.5447 56.0317 21.2772 40.7874 26.8044Z"
      fill={darken(0.02, color)}
    />
    <path
      d="M42.1244 55.2422C40.9655 51.4088 39.9848 46.4165 39.3608 40.9785C46.4035 51.0522 55.4966 60.5019 58.6168 58.3624L58.0819 57.6492C56.1206 58.9864 48.1865 52.2111 39.8957 40.2653C39.6282 39.9087 39.3608 39.5521 39.0934 39.1955C39.0934 38.7498 39.0042 38.304 39.0042 37.8583C37.8453 23.4163 39.6282 13.0751 41.9461 12.8969L41.8569 12.0054C38.0236 12.362 37.043 25.3776 38.0236 37.68C34.9925 33.1335 32.5856 28.6761 31.07 24.9318C29.0196 20.0287 29.1088 17.7108 29.9111 17.2651L29.3762 16.5519C27.8607 17.6217 28.5739 20.7419 29.5545 23.6838C29.7328 24.2187 30.0003 24.8427 30.1786 25.3776C31.8724 29.5675 34.7251 34.5598 38.1127 39.6413C38.7368 45.7925 39.7174 51.4088 41.0546 55.6879C41.2329 56.2228 41.4112 56.8468 41.5895 57.3817C42.6593 60.3236 44.1748 63.0872 45.9578 62.998L45.8686 62.1066C45.1554 62.0174 43.6399 60.3236 42.1244 55.2422Z"
      fill={darken(0.2, color)}
    />
  </svg>
);

export default withTheme(Zeplin);
