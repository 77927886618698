import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Twitter: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.7 0H2.3C1 0 0 1 0 2.3v27.4C0 31 1 32 2.3 32h27.4c1.3 0 2.3-1 2.3-2.3V2.3C32 1 31 0 29.7 0zm-6.4 12.5v.4a10.4 10.4 0 01-16 8.8 7.5 7.5 0 005.4-1.5c-1.6 0-3-1-3.5-2.5a3.7 3.7 0 001.7 0c-1.7-.4-3-2-3-3.7.6.2 1.1.4 1.7.4a3.7 3.7 0 01-1.1-4.9c1.8 2.2 4.5 3.7 7.6 3.9a3.7 3.7 0 016.2-3.4c.8-.2 1.6-.5 2.3-.9-.2.9-.8 1.6-1.6 2 .8 0 1.5-.2 2.1-.5-.5.7-1 1.4-1.8 1.9z"
      fill={color}
    />
  </svg>
);

export default withTheme(Twitter);
