import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Html: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 65.4L15 15h49l-4.5 50.4-20 5.6-20-5.6zm20-33.7h14.8l.5-6.2H24.1l1.6 18.7H47l-.7 8-6.8 1.9-6.9-1.9-.5-5.1H26l.9 9.9 12.5 3.5L52 57l1.7-19H31.3l-.5-6.3h8.7z"
      fill={color}
    />
  </svg>
);

export default withTheme(Html);
