import styled from 'styled-components';

export const StyledFormLoader = styled.div`
  padding-bottom: 20px;
  position: absolute;
  inset: 0;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 15;
`;

export const StyledFormLoaderIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  width: 263px;
  height: 174px;
`;
