import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const RubyOnRails: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.439 34.4238V39.9146H13.8394V38.4845L15.1139 39.759H17.2627L15.5583 38.0546C15.5583 38.0546 16.7844 38.0347 16.8772 36.2615C16.8772 34.6346 15.6496 34.4238 14.2318 34.4238H12.439ZM13.906 35.9132H15.1139V36.8913H13.906V35.9132Z"
      fill={color}
    />
    <path
      d="M19.9671 34.4455C19.2002 34.441 18.1367 34.5121 18.1367 35.9571V39.7734H19.5741V38.8396H20.9672V39.7511H22.4122V35.7497C22.4122 34.4946 21.0177 34.4455 20.2708 34.4455C20.1774 34.4455 20.0766 34.4462 19.9671 34.4455ZM19.5964 35.8236H20.9452V37.3279H19.5964V35.8236Z"
      fill={color}
    />
    <path d="M25.3402 34.4473H23.853V39.7H25.3402V34.4473Z" fill={color} />
    <path d="M26.8979 34.5193V39.772H30.477V38.3775H28.3622V34.4961L26.8979 34.5193Z" fill={color} />
    <path
      d="M35.2186 34.471V35.8887H32.8246V36.4C32.8246 36.4 33.3129 36.4 33.8471 36.4C34.3813 36.4 35.4972 36.3767 35.4972 38.0503C35.4972 39.7237 34.7304 39.8166 33.1732 39.8166C31.6158 39.8166 31.2907 39.8166 31.2907 39.8166V38.5381C31.2907 38.5381 32.8014 38.5381 33.4521 38.5381C34.1027 38.5381 34.1388 38.3261 34.1388 38.1416C34.1388 37.9571 33.8061 37.8711 33.1293 37.8711C32.4526 37.8711 31.1978 37.5386 31.1978 36.2371C31.1978 34.9355 31.8484 34.4473 33.15 34.4473C34.4515 34.4473 35.2186 34.471 35.2186 34.471Z"
      fill={color}
    />
    <path
      d="M13.7425 32.8675H26.1074C26.1074 32.8675 23.3649 26.6386 24.062 20.9211C24.7594 15.2037 29.2682 12.6003 31.8249 12.3215C34.3813 12.0424 35.776 13.623 35.776 13.623L36.334 12.7862C36.334 12.7862 32.6617 9.06746 28.0132 9.48597C23.3646 9.90426 20.1573 12.9721 18.1587 16.6909C16.1597 20.4099 14.9976 23.0128 14.2075 26.9174C13.4174 30.8221 13.7425 32.8675 13.7425 32.8675Z"
      fill={color}
    />
    <path d="M11.2789 26.6406L13.6961 26.8265L13.2775 29.2435L11 28.9647L11.2789 26.6406Z" fill={color} />
    <path d="M15.0904 21.5265L15.7413 19.7138L13.6029 18.877L12.9058 20.8292L15.0904 21.5265Z" fill={color} />
    <path d="M18.1118 15.2986L19.3669 13.8114L17.74 12.7422L16.4385 14.1829L18.1118 15.2986Z" fill={color} />
    <path d="M21.4126 9.53447L22.5283 10.8825L24.0622 9.99922L22.9465 8.74414L21.4126 9.53447Z" fill={color} />
    <path d="M26.8516 8L27.1304 9.34827L29.0364 9.30179L28.8502 8.09319L26.8516 8Z" fill={color} />
    <path d="M33.3121 9.43945L33.2656 10.276L34.5672 10.9731L34.846 10.5549L33.3121 9.43945Z" fill={color} />
    <path d="M33.0332 13.0195V13.7167L34.2883 13.8561V13.2981L33.0332 13.0195Z" fill={color} />
    <path d="M29.0361 13.6244L29.6403 14.7398L30.5236 14.0427L30.3377 13.2988L29.0361 13.6244Z" fill={color} />
    <path d="M27.1295 15.0645L28.0593 16.459L27.5014 17.4349L26.1533 15.9477L27.1295 15.0645Z" fill={color} />
    <path d="M25.2704 18.9238L24.666 20.1324L26.2464 21.4807L26.6182 19.9465L25.2704 18.9238Z" fill={color} />
    <path d="M24.6198 23.1523L24.5269 24.7792L26.4791 25.616L26.3861 24.0819L24.6198 23.1523Z" fill={color} />
    <path d="M25.1772 28.918L25.6422 30.5916L28.1057 30.731L27.2224 28.918H25.1772Z" fill={color} />
  </svg>
);

export default withTheme(RubyOnRails);
