import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledContent = styled.div`
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  margin: auto;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(6, 0)};

  ${mq.large`
    max-width: 940px;
    padding: ${({ theme }) => theme.spacing(11, 0, 13)};
  `}
`;

export const StyledOverflowColor = styled.div`
  background: ${({ theme }) => theme.gradients.darkViolet};
  opacity: 0.85;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledTitle = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 0, 5)};

  ${mq.large`
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  `}
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  ${mq.large`
    flex-direction: row;
    align-items: initial;
  `}
`;

export const StyledAuthorsContainer = styled.div`
  flex: 60% 0 0;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 40%;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing(3)};

  ${mq.large`
    flex-direction: row;
    margin-top: 0;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
  `}
`;

export const StyledCategoryContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(0, 6, 3, 0)};

  &:last-child {
    margin-right: 0;
  }

  ${mq.large`
    justify-content: center;
  `}
`;

export const StyledCategoryName = styled.span`
  margin-right: ${({ theme }) => theme.spacing(3)};
`;

export const StyledAuthorTileWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(0, 6, 2, 0)};

  &:last-child {
    margin-right: 0;
  }
`;

export const StyledContainer = styled.section`
  position: relative;

  ${mq.large`
    margin-bottom: ${({ theme }) => theme.spacing(-10)};
  `}
`;

export const StyledIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: ${({ theme }) => theme.spacing(2)};

  &:last-child {
    margin-right: 0;
  }
`;
