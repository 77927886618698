import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Photoshop: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.2 24.6l.6-.3h6.5c7.1 0 9.8 3.8 9.8 8.8 0 6.5-4.6 9.3-10.4 9.3h-2v9.8c0 .2 0 .3-.3.3h-3.9c-.2 0-.3-.1-.3-.3V24.6zm4.5 13.7h2.1c3 0 5.8-1 5.8-5.1 0-3.3-2-5-5.4-5l-2.5.2v9.9zm22-3.1c-2 0-2.8 1-2.8 1.9 0 .9.5 1.5 3.2 3 4 1.9 5.2 3.8 5.2 6.5 0 4.1-3 6.3-7.3 6.3a11 11 0 01-5.2-1c-.2-.2-.2-.3-.2-.5v-3.8c0-.2.1-.3.3-.2 1.6 1 3.5 1.5 5.1 1.5 2 0 2.9-.8 2.9-2 0-.9-.6-1.7-3.2-3-3.6-1.8-5-3.5-5-6.5 0-3.3 2.5-6 7-6 2.1 0 3.7.3 4.5.7.2.1.3.3.3.5V36c0 .2-.2.3-.4.3a8.6 8.6 0 00-4.5-1.2zM14.6 12h50.7c1.4 0 2.6 1.2 2.6 2.6v50.8c0 1.4-1.2 2.6-2.6 2.6H14.6a2.6 2.6 0 01-2.6-2.6V14.6c0-1.4 1.2-2.6 2.6-2.6z"
      fill={color}
    />
  </svg>
);

export default withTheme(Photoshop);
