import React, { FC } from 'react';
import { withTheme } from 'styled-components';
import { darken } from 'polished';

import { IconProps } from 'components/atoms/icons/Icon';

const Android: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46.2 46c-2 0-3.5 1.6-3.5 3.5v14a3.5 3.5 0 007 0v-14c0-2-1.6-3.5-3.5-3.5zM34.5 46c-2 0-3.5 1.6-3.5 3.5v14a3.5 3.5 0 007 0v-14c0-2-1.6-3.5-3.5-3.5z"
      fill={color}
    />
    <path
      d="M54.3 29.7h-28v21c0 2.5 2.1 4.6 4.7 4.6h18.7c2.5 0 4.6-2 4.6-4.6v-21zM60.2 29.7c-2 0-3.5 1.5-3.5 3.5v14a3.5 3.5 0 007 0v-14c0-2-1.6-3.5-3.5-3.5zM33.6 17.6c-.4 0-.7-.2-1-.6L30 12.8a1.2 1.2 0 012-1.2l2.6 4.2a1.2 1.2 0 01-1 1.8zM47 17.6a1.2 1.2 0 01-1-1.8l2.7-4.2a1.2 1.2 0 012 1.2L48 17c-.2.4-.6.6-1 .6z"
      fill={color}
    />
    <path d="M40.3 13.3a14 14 0 00-14 14h28a14 14 0 00-14-14z" fill={color} />
    <path
      d="M34.5 22.7a1.2 1.2 0 100-2.4 1.2 1.2 0 000 2.4zM46.2 22.7a1.2 1.2 0 100-2.4 1.2 1.2 0 000 2.4z"
      fill={darken(0.2, color)}
    />
    <path d="M20.5 29.7c-2 0-3.5 1.5-3.5 3.5v14a3.5 3.5 0 007 0v-14c0-2-1.6-3.5-3.5-3.5z" fill={color} />
  </svg>
);

export default withTheme(Android);
