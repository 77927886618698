import routes from './routes';

const { ROUTES } = routes;

enum KnowledgeTypes {
  Blog,
  CaseStudy,
  ExpertZone,
  FrontendNews
}

const KNOWLEDGE = {
  FILTERS: [
    {
      ...ROUTES.BLOG,
      id: KnowledgeTypes.Blog
    },
    {
      ...ROUTES.CASE_STUDY,
      id: KnowledgeTypes.CaseStudy
    },
    {
      ...ROUTES.EXPERT_ZONE,
      id: KnowledgeTypes.ExpertZone
    },
    {
      ...ROUTES.NEWS,
      id: KnowledgeTypes.FrontendNews
    }
  ],
  EXPERT_ZONE_ID: KnowledgeTypes.ExpertZone,
  CASE_STUDY_ID: KnowledgeTypes.CaseStudy,
  BLOG_ID: KnowledgeTypes.Blog,
  FRONTEND_NEWS_ID: KnowledgeTypes.FrontendNews
};

export default { KNOWLEDGE };
