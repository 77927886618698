import React, { FC } from 'react';
import { withTheme } from 'styled-components';
import { lighten, darken } from 'polished';

import { IconProps } from 'components/atoms/icons/Icon';

const Sketch: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.5 12l-17.2 1.9L9 31 39.5 68l30.6-37L56.8 14 39.5 12z" fill={color} />
    <path d="M9 31l30.5 37-18.1-37H9zM57.7 31L39.5 68l30.6-37H57.7z" fill={darken(0.1, color)} />
    <path d="M22.3 13.9l-1 17.2L39.6 12l-17.2 1.9zM57.7 31l-1-17.1L39.6 12l18.2 19z" fill={lighten(0.05, color)} />
    <path d="M57.7 31h12.4L56.8 14l1 17.2zM9 31h12.4l1-17.1L9 31z" fill={color} />
    <path d="M39.5 12L21.4 31h36.3L39.5 12z" fill={lighten(0.1, color)} />
    <path d="M21.4 31l18.1 37 18.2-37H21.4z" fill={color} />
  </svg>
);

export default withTheme(Sketch);
