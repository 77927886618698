import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Figma: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.33 68a9.33 9.33 0 009.34-9.33v-9.34h-9.34a9.33 9.33 0 100 18.67zM21 40a9.33 9.33 0 019.33-9.33h9.34v18.66h-9.34A9.33 9.33 0 0121 40z"
      fill={color}
    />
    <path
      d="M21 21.33A9.33 9.33 0 0130.33 12h9.34v18.67h-9.34A9.33 9.33 0 0121 21.33z"
      fill={color}
      fillOpacity=".75"
    />
    <path d="M39.67 12.05H49a9.33 9.33 0 010 18.67h-9.33V12.05z" fill={color} />
    <path d="M58.33 40a9.33 9.33 0 11-18.66 0 9.33 9.33 0 0118.66 0z" fill={color} />
  </svg>
);

export default withTheme(Figma);
