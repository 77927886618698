import React, { createContext, FC, useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import config from 'config';
import { ColorScheme } from 'theme';

export type ResponsiveTheme = {
  mobile: ColorScheme;
  desktop: ColorScheme;
};

type Values = {
  theme: ResponsiveTheme;
  setTheme: (theme: ResponsiveTheme) => void;
};

type HeaderContextProps = {
  defaultTheme?: ResponsiveTheme;
};

const { ROUTES } = config;

const getTheme = (mobile: ColorScheme, desktop: ColorScheme = mobile): ResponsiveTheme => ({
  mobile,
  desktop
});

export const HeaderContext = createContext<Values>({
  theme: getTheme('light'),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTheme: () => {}
});
const pathToTheme: Record<string, ResponsiveTheme> = {
  [ROUTES.HOME.href]: getTheme('dark', 'light'),
  [ROUTES.SOFTWARE.href]: getTheme('dark', 'light'),
  [ROUTES.EXTENSION.href]: getTheme('dark', 'light'),
  [ROUTES.NEWS.href]: getTheme('dark'),
  [ROUTES.SINGLE_FRONTEND_NEWS.href]: getTheme('dark', 'light'),
  [ROUTES.BLOG.href]: getTheme('dark'),
  [ROUTES.BLOG_ARTICLE.href]: getTheme('dark', 'light'),
  [ROUTES.EXPERT_ZONE.href]: getTheme('dark'),
  [ROUTES.EXPERT_ZONE_POST.href]: getTheme('dark', 'light'),
  [ROUTES.SINGLE_TECHNOLOGY_EXTENSION.href]: getTheme('dark'),
  [ROUTES.CASE_STUDY.href]: getTheme('light'),
  [ROUTES.SINGLE_CASE_STUDY.href]: getTheme('dark'),
  [ROUTES.SERVICES.href]: getTheme('dark', 'light')
};

const HeaderThemeProvider: FC<HeaderContextProps> = ({
  children,
  defaultTheme = { mobile: 'light', desktop: 'light' }
}) => {
  const [theme, setTheme] = useState<ResponsiveTheme>(defaultTheme);
  const { pathname } = useRouter();

  useEffect(() => {
    if (pathToTheme[pathname]) {
      setTheme(pathToTheme[pathname]);
    } else if (theme !== defaultTheme) {
      setTheme(defaultTheme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return <HeaderContext.Provider value={{ theme, setTheme }}>{children}</HeaderContext.Provider>;
};

export default HeaderThemeProvider;
