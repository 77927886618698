import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const CSS: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.0032 38.3498L24.1419 40.9998L14.2807 38.3498L12 13.6895H36.2766L34.0032 38.3498Z" fill={color} />
    <path
      opacity="0.4"
      d="M24.1416 15.6895V38.8873L24.1633 38.8945L32.1421 36.7442L33.9884 15.6895H24.1416Z"
      fill="white"
    />
    <path d="M18.5453 10.0699V8.6001H20.6885V7H16.938V11.5324H20.6885V10.0699H18.5453Z" fill={color} />
    <path
      d="M24.5403 8.6001H26.039V7H22.2886C22.2886 7 22.2886 7.80367 22.2886 8.60734C22.7882 9.10692 23.0488 9.34585 23.7728 10.0771C23.3457 10.0771 22.2886 10.0771 22.2886 10.0771V11.5397H26.039V10.0699L24.5403 8.6001Z"
      fill={color}
    />
    <path
      d="M29.8469 8.6001H31.3457V7H27.5952C27.5952 7 27.5952 7.80367 27.5952 8.60734C28.0948 9.10692 28.3554 9.34585 29.0867 10.0771C28.6595 10.0771 27.5952 10.0771 27.5952 10.0771V11.5397H31.3457V10.0699L29.8469 8.6001Z"
      fill={color}
    />
    <g opacity="0.05">
      <path
        opacity="0.05"
        d="M24.1346 32.5802L24.0984 32.5874L20.7896 31.7548L20.5869 29.3438H17.5098L17.908 33.992L24.1346 35.831V32.5802Z"
        fill="black"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1349 18.707H31.6937L31.6839 18.8029L31.6934 18.799L31.3893 21.7747L24.3299 24.7971H31.1362L30.3325 34.0285L24.1421 35.8313L17.9227 33.9923L17.5173 29.344H20.5944L20.7971 31.755L24.1132 32.5877L27.5595 31.6175L27.7912 27.7367L24.1349 27.7266V27.7295L17.5192 27.7084L17.3074 27.799L17.0757 24.8884L17.0827 24.8854L17.0757 24.7971H17.0828H17.2947L24.1346 21.949V21.9485L24.7722 21.6828H24.1349H16.8077L16.4385 18.707H24.1349Z"
      fill="white"
      fillOpacity="0.6"
    />
  </svg>
);

export default withTheme(CSS);
