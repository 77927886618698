import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Javascript: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68 12H12v56h56V12zM42.5 55.7c0 5.4-3.2 8-7.9 8a8.2 8.2 0 01-7.9-4.9l4.3-2.6c.8 1.5 1.6 2.7 3.4 2.7 1.7 0 2.8-.7 2.8-3.3V37.7h5.3v18zm2.8 2.5c1.6 3 4.7 5.4 9.6 5.4 5 0 8.7-2.6 8.7-7.3 0-4.4-2.5-6.4-7-8.3l-1.3-.5c-2.2-1-3.2-1.7-3.2-3.2 0-1.3 1-2.3 2.5-2.3s2.5.6 3.4 2.3l4.1-2.7c-1.7-3-4.1-4.2-7.5-4.2-4.7 0-7.7 3-7.7 7 0 4.3 2.5 6.3 6.3 7.9l1.3.6c2.5 1 3.9 1.7 3.9 3.5 0 1.5-1.4 2.6-3.6 2.6-2.6 0-4-1.4-5.2-3.2l-4.3 2.4z"
      fill={color}
    />
  </svg>
);

export default withTheme(Javascript);
