import React, { FC, ReactNode } from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';

import { Marked } from 'components/Marked/Marked';
import Container from 'components/atoms/Container/Container';
import { StyledContent, StyledMarkedContent, StyledContainer } from './ArticleBody.styles';

export type ArticleBodyProps = {
  content?: string | MDXRemoteSerializeResult;
  childrenBefore?: ReactNode;
  childrenAfter?: ReactNode;
};

export const ArticleBody: FC<ArticleBodyProps> = ({ content, childrenBefore, childrenAfter }) => (
  <StyledContainer>
    <StyledContent>
      <Container>
        {childrenBefore}
        {content && (
          <StyledMarkedContent>
            <Marked>{content}</Marked>
          </StyledMarkedContent>
        )}
        {childrenAfter}
      </Container>
    </StyledContent>
  </StyledContainer>
);
