import React, { FC, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import useMedia from 'hooks/useMedia';

import { PopupWidget } from './PopupWidget';
import { ButtonWidget } from './ButtonWidget';
import { WindowWidget } from './WindowWidget';

import { WidgetButtonWrapper } from './ContactWidget.styles';

export const ContactWidget: FC = () => {
  const [isWindow, setWindow] = useState(false);
  const [isPopup, setPopup] = useState(false);

  const { isMobile } = useMedia();

  return (
    <>
      <AnimatePresence>
        {isWindow && <WindowWidget hideWindow={() => setWindow(false)} isMobile={isMobile} />}
      </AnimatePresence>
      {!isWindow && (
        <WidgetButtonWrapper
          onClick={() => {
            setWindow(!isWindow);
            setPopup(false);
          }}
          onMouseLeave={() => setPopup(false)}
          onMouseOver={() => setPopup(true)}
        >
          <AnimatePresence>{isPopup && <PopupWidget />}</AnimatePresence>
          <ButtonWidget />
        </WidgetButtonWrapper>
      )}
    </>
  );
};
