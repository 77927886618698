import React, { FC, ReactNode } from 'react';
import Head from 'next/head';

import { getNextImgUrl } from 'helpers/image';
import UploadedImg from 'utils/types/UploadedImg';
import fromCMS from 'utils/fromCMS';
import breakpoints from 'theme/breakpoints';

import Container from 'components/atoms/Container/Container';
import { MobileOnlyImage } from 'components/MobileOnlyImage/MobileOnlyImage';
import Text from './Text/Text';

import {
  StyledContainer,
  StyledLeftColumn,
  StyledRightColumn,
  StyledBackground,
  StyledWrapper,
  StyledMobileBackgroundWrapper,
  StyledImageWrapper
} from './ContentBox.styles';

export type ColumnColors = 'dark' | 'white' | 'violet' | 'whisper' | 'purple';

export type ContentBoxProps = {
  leftColumnContent?: ReactNode;
  rightColumnContent?: ReactNode;
  leftColumnColor?: ColumnColors;
  rightColumnColor?: ColumnColors;
  crop?: 'leftToRight' | 'rightToLeft';
  geoShapes?: ReactNode;
  mobileBackground?: UploadedImg;
};

interface ContentBox extends FC<ContentBoxProps> {
  Text: typeof Text;
  ImageWrapper: typeof StyledImageWrapper;
}

const MOBILE_BACKGROUND_QUALITY = 50;

const ContentBox: ContentBox = ({
  leftColumnColor = 'purple',
  rightColumnColor = 'dark',
  crop,
  rightColumnContent = null,
  leftColumnContent = null,
  geoShapes = null,
  mobileBackground
}) => (
  <>
    {mobileBackground && (
      <Head>
        <link
          rel="preload"
          href={getNextImgUrl(fromCMS(mobileBackground.file.url), {
            quality: MOBILE_BACKGROUND_QUALITY,
            width: breakpoints.small
          })}
          as="image"
          media={`(max-width: ${breakpoints.mobileMax}px)`}
        />
      </Head>
    )}
    <StyledWrapper>
      <Container>
        {mobileBackground && (
          <StyledMobileBackgroundWrapper>
            <MobileOnlyImage
              alt={mobileBackground.alt}
              loading="eager"
              quality={MOBILE_BACKGROUND_QUALITY}
              src={fromCMS(mobileBackground.file.url)}
              cover
            />
          </StyledMobileBackgroundWrapper>
        )}
        <StyledBackground rightColumnColor={rightColumnColor} />
        <StyledContainer>
          {geoShapes}
          <StyledLeftColumn leftColumnColor={leftColumnColor} crop={crop}>
            {leftColumnContent}
          </StyledLeftColumn>
          <StyledRightColumn>{rightColumnContent}</StyledRightColumn>
        </StyledContainer>
      </Container>
    </StyledWrapper>
  </>
);

ContentBox.Text = Text;

ContentBox.ImageWrapper = StyledImageWrapper;

export default ContentBox;
