import { css } from 'styled-components';
import breakpoints from './breakpoints';

type CustomMediaQuery = (...cssValue: any) => any;

type Breakpoints = {
  xSmall: CustomMediaQuery;
  small: CustomMediaQuery;
  medium: CustomMediaQuery;
  mobileMax: CustomMediaQuery;
  large: CustomMediaQuery;
  xLarge: CustomMediaQuery;
  xsLarge: CustomMediaQuery;
  xmLarge: CustomMediaQuery;
  xxLarge: CustomMediaQuery;
  xxsLarge: CustomMediaQuery;
  xxxLarge: CustomMediaQuery;
  fourK: CustomMediaQuery;
};

export const mq: Breakpoints = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (first: any, ...interpolations: Array<any>) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(first, ...interpolations)}
    }
  `;

  return acc;
}, {} as Breakpoints);

export { breakpoints };
