import React, { FC, useState, useEffect, useContext, ReactElement } from 'react';
import { Router } from 'next/router';
import { Squash as Hamburger } from 'hamburger-react';
import { useTheme } from 'styled-components';

import config from 'config';
import { HeaderContext } from 'utils/HeaderThemeContext';
import { useMinWidthQuery } from 'hooks/useMedia';
import { groupBy } from 'utils/groupBy';
import { partition } from 'utils/partition';
import { AppLink } from 'components/AppLink/AppLink';
import Container from 'components/atoms/Container/Container';
import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import Logo from 'components/atoms/Logo/Logo';
import Typography from 'components/atoms/Typography/Typography';
import { ColorScheme } from 'theme';
import { isDesktopVisible, isMobileVisible, MenuStructure } from 'utils/types/Menu';

import DropdownMenu from './DropdownMenu/DropdownMenu';
import {
  StyledHeader,
  StyledNavBarItems,
  StyledNavBarItem,
  StyledHeaderContent,
  StyledButtonWrapper,
  StyledHamburgerWrapper,
  StyledMenu,
  StyledMenuList,
  StyledMenuItem,
  StyledMenuButtonWrapper,
  StyledMenuItemsWrapper,
  ActiveStyledNavbarLink,
  StyledHeaderBox,
  StyledNavBarLink
} from './Header.styles';

const { ROUTES } = config;

export type HeaderProps = {
  menuStructure: MenuStructure;
};

export const Header: FC<HeaderProps> = ({ menuStructure = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openedSubMenuId, setOpenedSubMenuId] = useState<string | null>(null);
  const isDesktop = useMinWidthQuery('xsLarge');
  const theme = useTheme();
  const { theme: colorSchemeVariants } = useContext(HeaderContext);
  const currentColorScheme = isDesktop ? colorSchemeVariants.desktop : colorSchemeVariants.mobile;
  const logoColorScheme: ColorScheme = currentColorScheme === 'light' ? 'dark' : 'light';

  const [subMenuItems, menuItems] = partition(menuStructure, (item) => typeof item.menu?.parentId === 'string');
  const subMenuItemsByHref = groupBy(subMenuItems, (item) => item.href);
  const subMenuItemsByParentId = groupBy(subMenuItems, (item) => item.menu.parentId);

  useEffect(() => {
    if (!isDesktop) {
      document.body.style.overflow = isOpen ? 'hidden' : null;

      return () => {
        document.body.style.overflow = null;
      };
    }
  }, [isOpen, isDesktop]);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false);
      setOpenedSubMenuId(null);
    };

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  const MobileHamburger = (): ReactElement => (
    <StyledHamburgerWrapper>
      <Hamburger
        color={currentColorScheme === 'light' && !isOpen ? theme.gradients.electricViolet : theme.colors.white}
        toggle={setIsOpen}
        toggled={isOpen}
        size={18}
        label="Main menu"
      />
    </StyledHamburgerWrapper>
  );

  const MobileMenuItem = ({ open, animation, href, text, isSubMenu = false }): ReactElement => {
    const hasHrefInSubMenu = subMenuItemsByHref.hasOwnProperty(href);
    const NavbarLink = !isSubMenu && hasHrefInSubMenu ? StyledNavBarLink : ActiveStyledNavbarLink;

    return (
      <StyledMenuItem isOpen={open} duration={animation} isSubMenu={isSubMenu}>
        <NavbarLink href={href} colorScheme={currentColorScheme} withNumbers={!isSubMenu} hasLeftHexagon>
          <Typography variant="h4" weight="400">
            {text}
          </Typography>
        </NavbarLink>
      </StyledMenuItem>
    );
  };

  const MobileMenu = (): ReactElement => (
    <StyledMenu isOpen={isOpen}>
      <StyledMenuList>
        <StyledMenuItemsWrapper>
          {menuItems.filter(isMobileVisible).map(({ id, animation, href, text, menu }) => (
            <>
              {!menu && (
                <MobileMenuItem
                  key={`mobile-menu-item-${id}`}
                  open={isOpen}
                  animation={animation}
                  href={href}
                  text={text}
                />
              )}
              {subMenuItemsByParentId[id]?.filter(isMobileVisible)?.map(({ id: subMenuId, animation, href, text }) => (
                <MobileMenuItem
                  key={`mobile-subMenu-item-${subMenuId}`}
                  open={isOpen}
                  animation={animation}
                  href={href}
                  text={text}
                  isSubMenu
                />
              ))}
            </>
          ))}
        </StyledMenuItemsWrapper>
      </StyledMenuList>
      <StyledMenuButtonWrapper isOpen={isOpen} duration={1.9}>
        <LinkedButton href={ROUTES.CONTACT.href}>{ROUTES.CONTACT.name}</LinkedButton>
      </StyledMenuButtonWrapper>
    </StyledMenu>
  );

  const HeadMenu = ({ id, href, text }): ReactElement => {
    const hasSubMenu = subMenuItemsByParentId.hasOwnProperty(id);

    // SUBMENU || MENU
    return (
      <>
        <StyledNavBarItem
          key={id}
          onMouseOver={() => {
            setOpenedSubMenuId(hasSubMenu ? id : null);
          }}
        >
          <ActiveStyledNavbarLink colorScheme={currentColorScheme} href={href}>
            {text}
          </ActiveStyledNavbarLink>
        </StyledNavBarItem>
      </>
    );
  };

  const DesktopMenu = (): ReactElement => (
    <StyledNavBarItems>
      {menuItems.filter(isDesktopVisible).map(({ id, href, text }) => (
        <HeadMenu key={id} id={id} href={href} text={text} />
      ))}
      {/* CONTACT */}
      <StyledButtonWrapper
        onMouseOver={() => {
          setOpenedSubMenuId(null);
        }}
      >
        <LinkedButton margin="0 0 0 18px" width="172px" height="40px" href={ROUTES.CONTACT.href}>
          {ROUTES.CONTACT.name}
        </LinkedButton>
      </StyledButtonWrapper>
    </StyledNavBarItems>
  );

  const DesktopSubMenu = (): ReactElement => (
    <>
      {menuItems
        .filter(({ id }) => subMenuItemsByParentId.hasOwnProperty(id))
        .map((menuItem) => (
          <DropdownMenu
            key={`desktop-subMenu-item-${menuItem.id}`}
            colorScheme={currentColorScheme}
            isVisible={openedSubMenuId === menuItem.id}
            menuItem={menuItem}
            subMenuItems={subMenuItemsByParentId[menuItem.id]}
            onMouseLeave={() => {
              setOpenedSubMenuId(null);
            }}
          />
        ))}
    </>
  );

  return (
    <StyledHeaderBox id="header">
      <StyledHeader colorScheme={isOpen ? 'dark' : currentColorScheme}>
        <Container>
          <StyledHeaderContent>
            <AppLink href="/">
              <Logo colorScheme={isOpen ? 'light' : logoColorScheme} wide />
            </AppLink>
            <nav>
              <MobileHamburger />
              <DesktopMenu />
            </nav>
          </StyledHeaderContent>
        </Container>
        <DesktopSubMenu />
      </StyledHeader>
      <MobileMenu />
    </StyledHeaderBox>
  );
};
