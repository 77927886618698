import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface PageEventProps {
  event: string;
  page: string;
}

export const GTMPageView = (url: string): PageEventProps => {
  const pageEvent: PageEventProps = {
    event: 'pageview',
    page: url
  };

  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  if (window && window.dataLayer) {
    // @ts-ignore
    window.dataLayer.push(pageEvent);
  }
  /* eslint-enable @typescript-eslint/ban-ts-comment */

  return pageEvent;
};

const GTM_ID = '__gtm';

export const useGTM = (isEnabled: boolean): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isAccepted, setIsAccepted] = useState(isEnabled);

  useEffect(() => {
    if (isAccepted) {
      const script = document.createElement('script');
      script.id = GTM_ID;
      script.type = 'application/javascript';
      script.setAttribute('async', 'true');
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-MDS7VJV');`;
      document.head.appendChild(script);
    }

    return () => {
      const script = document.getElementById(GTM_ID);

      if (script) {
        document.head?.removeChild(script);
      }
    };
  }, [isAccepted]);

  return [isAccepted, setIsAccepted];
};

export const renderGTMNoscript = (): JSX.Element => (
  <noscript
    dangerouslySetInnerHTML={{
      __html:
        '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MDS7VJV" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
    }}
  />
);
