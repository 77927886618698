import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const Node: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56.4924 100C55.3296 100 54.1732 99.696 53.1553 99.1054L42.5315 92.8071C40.9457 91.9173 41.7207 91.6028 42.2431 91.4201C44.3593 90.6843 44.7878 90.5143 47.0465 89.231C47.2815 89.0985 47.5937 89.1488 47.8366 89.2916L55.9978 94.147C56.2941 94.3114 56.7115 94.3114 56.983 94.147L88.8012 75.7454C89.0975 75.5762 89.2871 75.2346 89.2871 74.8827V38.0898C89.2871 37.7299 89.0967 37.3939 88.7933 37.2111L56.9886 18.8254C56.6955 18.6531 56.3045 18.6531 56.0082 18.8254L24.2115 37.2167C23.9025 37.3939 23.7066 37.741 23.7066 38.0898V74.8827C23.7066 75.2346 23.9017 75.5682 24.2083 75.735L32.9247 80.7812C37.654 83.1506 40.5491 80.3598 40.5491 77.5562V41.2285C40.5491 40.7154 40.9616 40.31 41.4738 40.31H45.5086C46.0135 40.31 46.4309 40.7154 46.4309 41.2285V77.5562C46.4309 83.8808 42.9934 87.508 37.0097 87.508C35.1707 87.508 33.7227 87.508 29.6807 85.512L21.3371 80.699C19.2767 79.5051 18 77.2729 18 74.8827V38.0898C18 35.6996 19.2759 33.4674 21.3371 32.2791L53.1553 13.8559C55.1687 12.7147 57.8448 12.7147 59.8423 13.8559L91.6581 32.2791C93.7185 33.473 95 35.6996 95 38.0898V74.8827C95 77.2729 93.7185 79.4971 91.6581 80.699L59.8423 99.1062C58.8252 99.6959 57.6712 100 56.4924 100Z"
      fill={color}
      fillOpacity="0.75"
    />
    <path
      d="M65.9922 75C51.9429 75 49 68.4733 49 62.9984C49 62.4778 49.4131 62.0622 49.9282 62.0622H54.079C54.5427 62.0622 54.9293 62.3997 55.0016 62.8585C55.6285 67.1353 57.4969 69.294 65.993 69.294C72.7565 69.294 75.6359 67.7453 75.6359 64.1135C75.6359 62.0199 74.8202 60.4655 64.3086 59.4235C55.5248 58.5442 50.0913 56.5799 50.0913 49.4667C50.0913 42.91 55.5513 39 64.7064 39C74.9914 39 80.0809 42.6131 80.7254 50.3647C80.7495 50.6315 80.6563 50.8886 80.4803 51.0878C80.3043 51.2741 80.0592 51.3872 79.8004 51.3872H75.6335C75.202 51.3872 74.8203 51.0773 74.7327 50.6535C73.7329 46.153 71.3019 44.7149 64.7064 44.7149C57.3217 44.7149 56.4634 47.3186 56.4634 49.2691C56.4634 51.6328 57.4768 52.3209 67.4468 53.6565C77.3131 54.9759 82 56.8459 82 63.8638C81.9984 70.9444 76.1655 75 65.9922 75Z"
      fill={color}
      fillOpacity="0.75"
    />
  </svg>
);
export default withTheme(Node);
