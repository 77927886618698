import React, { FC, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Image, { ImageProps } from 'next/image';

import { StyledWrapper } from './AppImage.styles';

export type AppImageProps = {
  hasFade?: boolean;
  isDisplayFlex?: boolean;
};

const AppImage: FC<ImageProps & AppImageProps> = ({ isDisplayFlex, hasFade = true, ...props }) => {
  const [isLoad, setIsLoad] = useState(false);
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <StyledWrapper ref={ref} isLoad={isLoad && inView} hasFade={hasFade} isDisplayFlex={isDisplayFlex}>
      <Image
        onLoadingComplete={() => {
          setIsLoad(true);
        }}
        {...props}
      />
    </StyledWrapper>
  );
};

export default AppImage;
