import React, { FC } from 'react';
import { Comment } from 'components/atoms/Comment/Comment';

import { fadeInFast } from 'theme/animations';

import { WidgetPopupSpan, AnimatedWidgetButtonPopup } from './PopupWidget.styles';

export const PopupWidget: FC = () => (
  <AnimatedWidgetButtonPopup initial="hidden" animate="visible" exit="hidden" variants={fadeInFast}>
    <WidgetPopupSpan />
    <Comment name="Michalina" comment="Hello! Let's discuss Your next project." image="/img/michalina.png" />
  </AnimatedWidgetButtonPopup>
);

export default PopupWidget;
