import styled from 'styled-components';

import { mq } from 'theme/media';
import fonts from '../../../theme/fonts';

import { FormProps } from './Inputs';

export const StyledForm = styled.form<FormProps>`
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 1;
  margin-bottom: 72px;
`;

export const StyledInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'top'
    'left'
    'right'
    'bottom';
  margin-bottom: 10px;
  grid-gap: 0 30px;

  ${mq.large`
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'top .'
      'left right'
      'bottom bottom';
  `}

  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const StyledInput = styled.div<FormProps>`
  position: relative;
  grid-area: ${({ gridArea }) => gridArea || '.'};
  margin-bottom: 12px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  ${mq.large`
    justify-content: flex-end;
  `}
`;

export const StyledLegalNoticeContainer = styled.div`
  margin-bottom: 12px;
`;

export const StyledLegalNotice = styled.button<FormProps>`
  ${({ theme, variant }) => theme.contactFormVariant[variant].agreement};
  border: 0;
  cursor: pointer;
  background-color: transparent;
  font-weight: 300;
  opacity: 0.5;
  line-height: 16px;
  user-select: text;
  font-size: 12px;
  text-align: start;
`;

export const StyledLabel = styled.label`
  ${fonts.body2};
  position: relative;
  display: inline-flex;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
`;
