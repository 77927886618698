import React, { FC } from 'react';

import {
  StyledFormError,
  StyledFormErrorInner,
  StyledFormErrorInnerIcon,
  StyledFormErrorInnerText,
  StyledFormErrorInnerButton,
  StyledContactAction
} from './Error.styles';

export const Error: FC = () => (
  <StyledFormError>
    <StyledFormErrorInner>
      <StyledFormErrorInnerIcon />
      <StyledFormErrorInnerText>
        Sorry, an error has occured. Please try again later or contact us and report the problem to us.
      </StyledFormErrorInnerText>
      <StyledFormErrorInnerButton>
        Send info to administration:{' '}
        <StyledContactAction href="mailto:hello@likims.com" title="Contact email">
          hello@likims.com
        </StyledContactAction>
      </StyledFormErrorInnerButton>
    </StyledFormErrorInner>
  </StyledFormError>
);

export default Error;
