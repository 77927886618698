import React, { FC } from 'react';
import Image from '@next/image';

import { FuncButton } from 'components/atoms/Button/FuncButton/FuncButton';
import Typography from 'components/atoms/Typography/Typography';
import Hexagon from 'components/atoms/Geometric/Hexagon/Hexagon';

import useMedia from 'hooks/useMedia';
import config from 'config';
import CookiesHandler from 'utils/Cookies';

import { StyledContainer, StyledDescription, StyledButtons, StyledDescriptionContainer } from './Cookies.styles';

const { COOKIES_ACCEPTED_KEY } = config;

type CookiesProps = {
  onAnswerClick: (isAccepted: boolean) => void;
};

export const Cookies: FC<CookiesProps> = ({ onAnswerClick }) => {
  const { isMobile } = useMedia();
  const width = isMobile ? '184px' : '136px';

  const handleAcceptClick = () => {
    CookiesHandler.setCookie(COOKIES_ACCEPTED_KEY, true, 9999);
    onAnswerClick(true);
  };

  return (
    <StyledContainer>
      <StyledDescriptionContainer>
        <Hexagon
          variant="purple"
          opacity={0.07}
          width="42px"
          top={isMobile ? '-10px' : '0'}
          left={isMobile ? '80px' : '130px'}
        />
        <Hexagon variant="purple" opacity={0.07} width="71px" top="20px" left={isMobile ? '200px' : '240px'} />
        <Image src="/icons/cookies.svg" alt="FrontendHouse logo on cookie banner" width={69} height={80} />
        <StyledDescription>
          <Typography variant="body2">We use cookies in order to personalize your site experience.</Typography>
        </StyledDescription>
      </StyledDescriptionContainer>
      <StyledButtons>
        <FuncButton width={width} height="40px" variant="secondary-dark" onClick={() => onAnswerClick(false)}>
          Decline
        </FuncButton>
        <FuncButton
          width={width}
          height="40px"
          margin={`0 0 0 ${isMobile ? '10%' : '16px'}`}
          onClick={handleAcceptClick}
        >
          Allow
        </FuncButton>
      </StyledButtons>
    </StyledContainer>
  );
};
