import React, { FC } from 'react';
import { withTheme } from 'styled-components';

import { IconProps } from 'components/atoms/icons/Icon';

const AdobeXd: FC<IconProps> = ({ theme, color = theme.colors.cloudBurst }) => (
  <svg viewBox="0 0 80 80" width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M47.3 43.5c0 6.3 4 6.8 5.3 6.8 1.3 0 1.8-.2 1.8-.2v-13s-.3-.3-1.4-.3c-1.1 0-5.7.5-5.7 6.7z" fill={color} />
    <path
      d="M16 12a4 4 0 00-4 4v48a4 4 0 004 4h48a4 4 0 004-4V16a4 4 0 00-4-4H16zm20.3 42L31 43l-5.3 11H21L28 39.7l-7-14h5l5.1 10.8 5.2-10.7h4.8L34 39.6 41.5 54h-5.2zM59 52.6s-2.2 1.6-7 1.6c-6.4 0-9.5-4.5-9.5-10.5C42.6 36 48.3 33 53 33h1.4v-7.1h4.7v26.8z"
      fill={color}
    />
  </svg>
);

export default withTheme(AdobeXd);
