import styled, { css, keyframes, DefaultTheme } from 'styled-components';
import { rgba } from 'polished';
import { motion } from 'framer-motion';

import { RhombusProps } from './Rhombus';

const horizontalMoveKeyframes = (distance: string) => keyframes`
  0% {
   transform: translateX(0px);
  } 
  50% {
    transform: translateX(${distance});
  }
  100% {
    transform: translateX(0px);
  }
`;

const rhombusVariant = (theme: DefaultTheme) => ({
  purple: {
    background: theme.gradients.purple
  },
  blue: {
    background: `linear-gradient(90deg, ${theme.colors.daisyBush} 0%, ${theme.colors.malibu} 100%)`
  },
  'transparent-purple': {
    background: `linear-gradient(135deg, ${rgba(theme.colors.electricViolet, 0.4)} 0%, ${rgba(
      theme.colors.cornflowerBlue,
      0.4
    )} 100%)`
  },
  'violet-gray': {
    background: theme.colors.whiteLilac
  },
  ghost: {
    background: 'transparent',
    border: '1px solid',
    borderImageSource: theme.gradients.electricViolet,
    borderImageSlice: '1'
  },
  ghostThick: {
    background: 'transparent',
    border: '2px solid',
    borderImageSource: theme.gradients.electricViolet,
    borderImageSlice: '1'
  },
  dashed: {
    background: 'transparent',
    border: `2px dashed ${theme.colors.electricViolet}`,
    opacity: 0.25
  },
  white: {
    background: theme.colors.white
  },
  'transparent-white': {
    background: 'transparent',
    border: `1px solid ${theme.colors.white}`
  },
  whisper: {
    background: theme.colors.whisper
  }
});

export const StyledRhombusContainer = styled(motion.div)<RhombusProps>`
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'absolute')};
  width: ${({ width }) => width};
  height: ${({ width }) => width};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${({ moveDuration }) => moveDuration} ${({ moveDistance }) => horizontalMoveKeyframes(moveDistance)}
    ${({ moveDelay }) => moveDelay} infinite ease-in-out;
  z-index: ${({ zIndex }) => zIndex || -1};
  opacity: ${({ opacity }) => opacity};
`;

export const StyledRhombusShape = styled.div<{
  variant: RhombusProps['variant'];
  rotate: RhombusProps['rotate'];
  hasShadow: RhombusProps['hasShadow'];
  hasClip: RhombusProps['hasClip'];
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 6;

  ${({ hasClip }) =>
    hasClip ? 'clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)' : 'transform: rotate(-135deg)'};
  ${({ variant, theme }) => css(rhombusVariant(theme)[variant])};

  &::before {
    display: ${({ hasShadow }) => (hasShadow ? 'block' : 'none')};
    content: '';
    position: absolute;
    left: -76%;
    top: -77%;
    width: 253%;
    height: 253%;
    ${({ variant, theme }) => css(rhombusVariant(theme)[variant])};
    opacity: 0.05;
    z-index: 4;
  }

  &::after {
    display: ${({ hasShadow }) => (hasShadow ? 'block' : 'none')};
    content: '';
    position: absolute;
    left: -36%;
    top: -35%;
    width: 170%;
    height: 170%;
    ${({ variant, theme }) => css(rhombusVariant(theme)[variant])};
    opacity: 0.1;
    z-index: 5;
  }
`;

export const StyledChildrenWrapper = styled.div<{
  rotate: RhombusProps['rotate'];
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(${rotate});
    `};
`;
