import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AnimatePresence } from 'framer-motion';

import { FuncButton } from 'components/atoms/Button/FuncButton/FuncButton';
import { Portal } from 'components/Portal/Portal';

import CMS from 'utils/cms/strapi';
import { getBrowserInfo } from 'utils/client';
import isBrowser from 'utils/isBrowser';
import { saveNewContactForm } from 'utils/queries/contactForm';

import config from 'config';

import { Loader as LoaderStatus } from './Loader';
import { Success as SuccessStatus } from './Success';
import { Error as ErrorStatus } from './Error';

import {
  StyledForm,
  StyledInputs,
  StyledButtonContainer,
  StyledLegalNotice,
  StyledInput,
  StyledLegalNoticeContainer
} from './Inputs.styles';
import { Input } from './Input/Input';
import { TextArea } from './TextArea/TextArea';
import { Policy } from './Policy/Policy';

export interface InputsProps {
  name: string;
  email: string;
  phone: number;
  optional: string;
}

export type FormProps = {
  gridArea?: 'top' | 'left' | 'right' | 'bottom';
  variant?: 'onWhite' | 'onViolet';
};

/**
 * form statuses
 */
const StatusForm = {
  default: 0,
  pending: 1,
  error: 2,
  success: 3
};

const pushToDataLayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'newform' });
};

export const Inputs: FC<FormProps> = ({ variant = 'onViolet' }) => {
  const [state, setStatus] = useState(StatusForm.default);
  const [shouldShowPolicy, setShouldShowPolicy] = useState(false);
  const { register, handleSubmit, errors, formState } = useForm<InputsProps>({
    mode: 'onChange'
  });

  const save = (name: string, email: string, message: string, phone: string) => {
    setStatus(StatusForm.pending);
    const newContactForm = new CMS([() => saveNewContactForm(name, email, message, phone, getBrowserInfo())]);
    newContactForm
      .setCreate()
      .then(([contact]) => {
        if (!contact?.data?.createNewContactForm?.newContactForm?.id) {
          throw new Error('problem with saving data');
        }
        setStatus(StatusForm.success);
        isBrowser && pushToDataLayer();
      })
      .catch(() => {
        setStatus(StatusForm.error);
      });
  };

  const onSubmit = (data: InputsProps) => {
    save(data.name, data.email, data.optional, data.phone.toString());
  };

  return (
    <>
      {state === StatusForm.default && (
        <StyledForm
          variant={variant}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && (e.target as Element).nodeName !== 'TEXTAREA') {
              e.preventDefault();
            }
          }}
        >
          <StyledInputs>
            <StyledInput gridArea="top">
              <Input
                variant={variant}
                label="Name"
                name="name"
                placeholder="Personal or company name"
                register={register({ required: true })}
                error={errors.name}
              />
            </StyledInput>
            <StyledInput gridArea="left">
              <Input
                variant={variant}
                label="Company email address"
                name="email"
                placeholder="youremail@address.here"
                register={register({
                  required: true,
                  pattern: config.EMAIL_VALIDATION_REGEX
                })}
                error={errors.email}
              />
            </StyledInput>
            <StyledInput gridArea="right">
              <Input
                variant={variant}
                label="Phone"
                name="phone"
                placeholder="Contact phone number"
                register={register({ pattern: config.PHONE_VALIDATION_REGEX })}
                error={errors.phone}
                isOptional
              />
            </StyledInput>
            <StyledInput gridArea="bottom">
              <TextArea
                label="Message"
                placeholder="Project details"
                name="optional"
                register={register()}
                variant={variant}
                isOptional
              />
            </StyledInput>
          </StyledInputs>
          <StyledLegalNoticeContainer>
            <StyledLegalNotice type="button" variant={variant} onClick={() => setShouldShowPolicy(true)}>
              By clicking <strong>Send</strong>, you agree to our <strong>Terms & Conditions</strong>.
            </StyledLegalNotice>
          </StyledLegalNoticeContainer>
          <StyledButtonContainer>
            <FuncButton
              variant={variant === 'onWhite' ? 'primary' : 'secondary-white'}
              margin="35px 0 0"
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isValid}
            >
              Send
            </FuncButton>
          </StyledButtonContainer>
        </StyledForm>
      )}
      {state === StatusForm.success && <SuccessStatus />}
      {state === StatusForm.pending && <LoaderStatus />}
      {state === StatusForm.error && <ErrorStatus />}
      <AnimatePresence>
        {shouldShowPolicy && (
          <Portal>
            <Policy hidePolicy={() => setShouldShowPolicy(false)} />
          </Portal>
        )}
      </AnimatePresence>
    </>
  );
};
