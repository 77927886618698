import styled, { css } from 'styled-components';

import { mq } from 'theme/media';

import { LogoProps } from './Logo';

export const StyledWrapper = styled.div<{ wide: LogoProps['wide'] }>`
  display: none;
  align-items: center;

  ${mq.xsLarge`
    display: flex;
  `}

  ${({ wide }) =>
    wide &&
    css`
      height: 48px;
    `}

    ${mq.large`
      padding-left: ${({ theme }) => theme.spacing(1)};
    `}
`;

export const StyledLogo = styled.svg<{ wide: LogoProps['wide'] }>`
  position: relative;
  z-index: 1;

  ${({ wide }) =>
    wide &&
    css`
      transform: scale(0.79) translate(-9px, -10px);
    `}
`;

export const StyledLogoWrapper = styled.div<{ wide: LogoProps['wide'] }>`
  position: relative;
  z-index: 1;
  width: 50px;
  height: 58px;

  ${({ wide }) =>
    wide &&
    css`
      height: 43px;
      width: 38px;
      transform: translateX(-8px);
    `}
`;

export const StyledNameWrapper = styled.svg`
  transform: translateX(-50px);
`;

export const StyledMobileSvg = styled.svg`
  ${mq.xsLarge`
    display: none;
  `}
`;
