import React, { FC, ReactNode } from 'react';

import { StyledHexagon, StyledChildrenWrapper } from './Hexagon.styles';

export type HexagonProps = {
  width: string;
  top?: string;
  left?: string;
  zIndex?: string | number;
  variant: 'blue' | 'purple' | 'white' | 'prelude' | 'whisper' | 'transparent';
  position?: 'relative' | 'absolute';
  opacity?: number;
  children?: ReactNode | null;
  rotate?: string;
};

const Hexagon: FC<HexagonProps> = ({ width, top, left, rotate, zIndex, variant, position, opacity, children }) => (
  <StyledHexagon
    width={width}
    top={top}
    left={left}
    zIndex={zIndex}
    variant={variant}
    position={position}
    opacity={opacity}
    rotate={rotate}
  >
    {children && <StyledChildrenWrapper rotate={rotate}>{children}</StyledChildrenWrapper>}
  </StyledHexagon>
);

export default Hexagon;
