import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { AppLink } from 'components/AppLink/AppLink';

import { withActivePath } from 'helpers/withActivePath';
import { ColorScheme } from 'theme';
import { mq } from 'theme/media';

export const StyledHeaderBox = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 5;
`;

export const StyledHeader = styled.div<{ colorScheme: ColorScheme }>`
  position: relative;
  z-index: 2;
  background-color: ${({ colorScheme, theme }) =>
    colorScheme === 'dark' ? theme.colors.blackRock : theme.colors.white};
`;

export const StyledButtonWrapper = styled.div`
  display: inline-block;
`;

export const StyledNavBarItems = styled.ul`
  display: none;
  white-space: nowrap;

  ${mq.xsLarge`
    display: inline-block;
  `}
`;

export const StyledMenuItem = styled.div<{ isOpen?: boolean; duration?: number; isSubMenu?: boolean }>`
  counter-increment: ${({ isSubMenu }) => (isSubMenu ? null : 'listCounter')};
  padding-left: ${({ isSubMenu, theme }) => (isSubMenu ? theme.spacing(9) : null)};
  position: relative;
  padding-bottom: ${({ theme }) => theme.spacing(4)};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  transition: ${({ duration, isOpen }) =>
    !isOpen
      ? 'all 0s ease-in-out'
      : `transform ${duration}s ease-in-out, 
        opacity 1s ${duration - 0.3}s ease`};
`;

export const StyledNavBarItem = styled.li`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

export const StyledNavBarLink = styled(AppLink)<{
  active?: boolean;
  withNumbers?: boolean;
  colorScheme?: ColorScheme;
  hasLeftHexagon?: boolean;
}>`
  position: relative;
  display: block;

  ${({ active }) =>
    active &&
    css`
      ${mq.xsLarge`
        font-weight: 700;
      `}
    `}

  ${mq.xsLarge`
    color: ${({ theme, colorScheme }) => (colorScheme === 'dark' ? theme.colors.white : theme.colors.blackRock)};
  `}

  ${({ active, theme }) =>
    active &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 4px;
        height: 4px;
        background: ${theme.gradients.electricViolet};
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(6px) rotate(45deg);
      }
    `};

  ${({ withNumbers }) =>
    withNumbers &&
    css`
      &::before {
        ${({ theme }) => theme.fonts.h4};
        font-weight: 700;
        color: ${({ theme }) => rgba(theme.colors.white, 0.2)};
        content: counter(listCounter, decimal-leading-zero);
        margin-right: ${({ theme }) => theme.spacing(2)};
      }
    `}

  ${({ active, hasLeftHexagon = false }) =>
    active &&
    hasLeftHexagon &&
    css`
      margin-left: ${({ theme }) => theme.spacing(4)};

      &::before {
        color: ${({ theme }) => theme.colors.white};
      }

      &::after {
        top: 0;
        margin: auto 0;
        width: 8px;
        height: 8px;
        left: ${({ theme }) => theme.spacing(-4)};
        transform: rotate(45deg);
      }
    `}

  ${({ withNumbers, colorScheme }) =>
    withNumbers &&
    colorScheme === 'light' &&
    css`
      &::before {
        color: ${({ theme }) => rgba(theme.colors.violetGray, 0.4)};
      }
    `}

  ${({ withNumbers, active, colorScheme }) =>
    withNumbers &&
    active &&
    colorScheme === 'light' &&
    css`
      &::before {
        color: ${({ theme }) => theme.colors.heliotrope};
      }
    `}

  ${mq.xsLarge`
    &:hover {
      color: ${({ theme }) => theme.colors.cornflowerBlue};
    }
  `}
`;

export const ActiveStyledNavbarLink = withActivePath(StyledNavBarLink);

export const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  justify-content: space-between;
  height: 56px;

  ${mq.xsLarge`
    height: 88px;
  `}
`;

export const StyledHamburgerWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing(-2)};

  ${mq.xsLarge`
    display: none;
  `}
`;

export const StyledMenu = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: ${({ theme }) => theme.spacing(7)};
  width: 100%;
  height: calc(100% - ${({ theme }) => theme.spacing(7)});
  color: ${({ theme }) => theme.colors.white};
  background: linear-gradient(
    214.84deg,
    ${({ theme }) => theme.colors.violet} 20.52%,
    ${({ theme }) => theme.colors.blackRock} 89.43%
  );
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-100%)')};
  transition: transform 0.3s ease;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mq.xsLarge`
    display: none;
  `}
`;

export const StyledMenuList = styled.div`
  display: flex;
  flex-direction: column;
  counter-reset: listCounter;
  align-self: center;
  width: 100%;
  overflow: auto;
  align-items: center;
  mask: linear-gradient(
    to top,
    transparent ${({ theme }) => theme.spacing(2)},
    black ${({ theme }) => theme.spacing(8)}
  );
`;

export const StyledMenuButtonWrapper = styled.div<{
  isOpen?: boolean;
  duration?: number;
}>`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacing(4)};
  justify-content: center;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  transition: ${({ duration, isOpen }) =>
    !isOpen
      ? 'transform 0s ease-in-out, opacity 0s ease-in-out'
      : `transform ${duration}s ease-in-out, opacity 1s ${duration - 0.3}s ease-in-out`};
  transition-delay: ${({ isOpen }) => (isOpen ? '0.3' : '0')};
`;

export const StyledMenuItemsWrapper = styled.div`
  display: inline-block;
  padding: ${({ theme }) => theme.spacing(4, 0)};
`;

export const StyledDropdownMenuTrigger = styled(AppLink)<{ isOpenDropdownMenu: boolean; colorScheme?: ColorScheme }>`
  margin-right: ${({ theme }) => theme.spacing(8)};
  cursor: pointer;
  color: ${({ colorScheme, theme }) => (colorScheme === 'dark' ? theme.colors.white : theme.colors.blackRock)};

  ${({ isOpenDropdownMenu, theme }) =>
    isOpenDropdownMenu &&
    css`
      color: ${theme.colors.heliotrope};
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.heliotrope};
  }
`;
