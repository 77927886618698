import React, { FC, ImgHTMLAttributes } from 'react';
import { getNextImgUrl } from 'helpers/image';

import { LARGEST_IMAGE_SIZE, SIZES, getSrcSet } from './MobileOnlyImage.utils';
import { StyledImage } from './MobileOnlyImage.styles';

type ImageProps = ImgHTMLAttributes<HTMLImageElement>;

export type MobileOnlyImageProps = Omit<ImageProps, 'srcSet' | 'sizes'> & {
  cover?: boolean;
  quality?: number;
};

export const MobileOnlyImage: FC<MobileOnlyImageProps> = ({ cover = false, src, quality = 75, ...props }) => (
  <StyledImage
    cover={cover}
    decoding="async"
    loading="lazy"
    {...props}
    src={getNextImgUrl(src, { quality, width: LARGEST_IMAGE_SIZE })}
    sizes={SIZES}
    srcSet={getSrcSet(src, quality)}
  />
);
