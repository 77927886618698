import styled from 'styled-components';

import fonts from '../../../../theme/fonts';

type InputErrorProps = {
  isError?: boolean;
  height?: string;
  margin?: string;
  variant?: string;
};

export const StyledWrapper = styled.div<InputErrorProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: ${({ margin }) => margin || '0'};

  &:after {
    content: '';
    position: absolute;
    background: url('/icons/warning.svg') no-repeat;
    background-position: center center;
    background-size: cover;
    height: 24px;
    width: 24px;
    right: 16px;
    top: 45%;
    display: ${({ isError }) => (isError ? 'inline-block' : 'none')};
  }
`;

export const StyledLabel = styled.label<InputErrorProps>`
  ${fonts.body2};
  color: ${({ theme, isError, variant }) =>
    isError ? theme.colors.persimmon : theme.contactFormVariant[variant].label.color};
`;

export const StyledOptionalLabel = styled(StyledLabel)`
  opacity: 0.5;
`;

export const StyledInput = styled.input<InputErrorProps>`
  ${({ theme, variant }) => theme.contactFormVariant[variant].input};
  position: relative;
  color: ${({ theme }) => theme.colors.cloudBurst};
  border-radius: 4px;
  border: 2px solid ${({ theme, isError }) => (isError ? theme.colors.persimmon : 'transparent')};
  height: ${({ height }) => height || '40px'};
  padding: ${({ theme }) => theme.spacing(0, 2)};
  margin-top: ${({ theme }) => theme.spacing(1)};
  background-color: ${(props) => props.theme.colors.white};

  outline: none;
  font-weight: 500;
  overflow: hidden;
  font-size: 14px;
  width: 100%;

  &:focus {
    border: 2px solid ${({ theme, isError }) => (isError ? theme.colors.persimmon : theme.colors.electricViolet)};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.cloudBurst};
    opacity: 0.5;
    font-size: 14px;
  }
`;
